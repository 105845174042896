/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.UserProfileProto = (function() {

    /**
     * Properties of a UserProfileProto.
     * @exports IUserProfileProto
     * @interface IUserProfileProto
     * @property {string|null} [profileId] UserProfileProto profileId
     * @property {string|null} [name] UserProfileProto name
     * @property {Array.<IClientAppointmentProto>|null} [appointments] UserProfileProto appointments
     * @property {space.kenko.proto.IWellnessSurveyProto|null} [wellnessSurveyAnswers] UserProfileProto wellnessSurveyAnswers
     */

    /**
     * Constructs a new UserProfileProto.
     * @exports UserProfileProto
     * @classdesc Represents a UserProfileProto.
     * @implements IUserProfileProto
     * @constructor
     * @param {IUserProfileProto=} [properties] Properties to set
     */
    function UserProfileProto(properties) {
        this.appointments = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserProfileProto profileId.
     * @member {string} profileId
     * @memberof UserProfileProto
     * @instance
     */
    UserProfileProto.prototype.profileId = "";

    /**
     * UserProfileProto name.
     * @member {string} name
     * @memberof UserProfileProto
     * @instance
     */
    UserProfileProto.prototype.name = "";

    /**
     * UserProfileProto appointments.
     * @member {Array.<IClientAppointmentProto>} appointments
     * @memberof UserProfileProto
     * @instance
     */
    UserProfileProto.prototype.appointments = $util.emptyArray;

    /**
     * UserProfileProto wellnessSurveyAnswers.
     * @member {space.kenko.proto.IWellnessSurveyProto|null|undefined} wellnessSurveyAnswers
     * @memberof UserProfileProto
     * @instance
     */
    UserProfileProto.prototype.wellnessSurveyAnswers = null;

    /**
     * Creates a new UserProfileProto instance using the specified properties.
     * @function create
     * @memberof UserProfileProto
     * @static
     * @param {IUserProfileProto=} [properties] Properties to set
     * @returns {UserProfileProto} UserProfileProto instance
     */
    UserProfileProto.create = function create(properties) {
        return new UserProfileProto(properties);
    };

    /**
     * Encodes the specified UserProfileProto message. Does not implicitly {@link UserProfileProto.verify|verify} messages.
     * @function encode
     * @memberof UserProfileProto
     * @static
     * @param {IUserProfileProto} message UserProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserProfileProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.profileId != null && Object.hasOwnProperty.call(message, "profileId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.profileId);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.appointments != null && message.appointments.length)
            for (var i = 0; i < message.appointments.length; ++i)
                $root.ClientAppointmentProto.encode(message.appointments[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.wellnessSurveyAnswers != null && Object.hasOwnProperty.call(message, "wellnessSurveyAnswers"))
            $root.space.kenko.proto.WellnessSurveyProto.encode(message.wellnessSurveyAnswers, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UserProfileProto message, length delimited. Does not implicitly {@link UserProfileProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UserProfileProto
     * @static
     * @param {IUserProfileProto} message UserProfileProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserProfileProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a UserProfileProto message from the specified reader or buffer.
     * @function decode
     * @memberof UserProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UserProfileProto} UserProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserProfileProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserProfileProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profileId = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    if (!(message.appointments && message.appointments.length))
                        message.appointments = [];
                    message.appointments.push($root.ClientAppointmentProto.decode(reader, reader.uint32()));
                    break;
                }
            case 4: {
                    message.wellnessSurveyAnswers = $root.space.kenko.proto.WellnessSurveyProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a UserProfileProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UserProfileProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UserProfileProto} UserProfileProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserProfileProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a UserProfileProto message.
     * @function verify
     * @memberof UserProfileProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UserProfileProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            if (!$util.isString(message.profileId))
                return "profileId: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.appointments != null && message.hasOwnProperty("appointments")) {
            if (!Array.isArray(message.appointments))
                return "appointments: array expected";
            for (var i = 0; i < message.appointments.length; ++i) {
                var error = $root.ClientAppointmentProto.verify(message.appointments[i]);
                if (error)
                    return "appointments." + error;
            }
        }
        if (message.wellnessSurveyAnswers != null && message.hasOwnProperty("wellnessSurveyAnswers")) {
            var error = $root.space.kenko.proto.WellnessSurveyProto.verify(message.wellnessSurveyAnswers);
            if (error)
                return "wellnessSurveyAnswers." + error;
        }
        return null;
    };

    /**
     * Creates a UserProfileProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UserProfileProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UserProfileProto} UserProfileProto
     */
    UserProfileProto.fromObject = function fromObject(object) {
        if (object instanceof $root.UserProfileProto)
            return object;
        var message = new $root.UserProfileProto();
        if (object.profileId != null)
            message.profileId = String(object.profileId);
        if (object.name != null)
            message.name = String(object.name);
        if (object.appointments) {
            if (!Array.isArray(object.appointments))
                throw TypeError(".UserProfileProto.appointments: array expected");
            message.appointments = [];
            for (var i = 0; i < object.appointments.length; ++i) {
                if (typeof object.appointments[i] !== "object")
                    throw TypeError(".UserProfileProto.appointments: object expected");
                message.appointments[i] = $root.ClientAppointmentProto.fromObject(object.appointments[i]);
            }
        }
        if (object.wellnessSurveyAnswers != null) {
            if (typeof object.wellnessSurveyAnswers !== "object")
                throw TypeError(".UserProfileProto.wellnessSurveyAnswers: object expected");
            message.wellnessSurveyAnswers = $root.space.kenko.proto.WellnessSurveyProto.fromObject(object.wellnessSurveyAnswers);
        }
        return message;
    };

    /**
     * Creates a plain object from a UserProfileProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UserProfileProto
     * @static
     * @param {UserProfileProto} message UserProfileProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UserProfileProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.appointments = [];
        if (options.defaults) {
            object.profileId = "";
            object.name = "";
            object.wellnessSurveyAnswers = null;
        }
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            object.profileId = message.profileId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.appointments && message.appointments.length) {
            object.appointments = [];
            for (var j = 0; j < message.appointments.length; ++j)
                object.appointments[j] = $root.ClientAppointmentProto.toObject(message.appointments[j], options);
        }
        if (message.wellnessSurveyAnswers != null && message.hasOwnProperty("wellnessSurveyAnswers"))
            object.wellnessSurveyAnswers = $root.space.kenko.proto.WellnessSurveyProto.toObject(message.wellnessSurveyAnswers, options);
        return object;
    };

    /**
     * Converts this UserProfileProto to JSON.
     * @function toJSON
     * @memberof UserProfileProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UserProfileProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UserProfileProto
     * @function getTypeUrl
     * @memberof UserProfileProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UserProfileProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UserProfileProto";
    };

    return UserProfileProto;
})();

$root.GetNetworkConfigurationRequestProto = (function() {

    /**
     * Properties of a GetNetworkConfigurationRequestProto.
     * @exports IGetNetworkConfigurationRequestProto
     * @interface IGetNetworkConfigurationRequestProto
     */

    /**
     * Constructs a new GetNetworkConfigurationRequestProto.
     * @exports GetNetworkConfigurationRequestProto
     * @classdesc Represents a GetNetworkConfigurationRequestProto.
     * @implements IGetNetworkConfigurationRequestProto
     * @constructor
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationRequestProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetNetworkConfigurationRequestProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto instance
     */
    GetNetworkConfigurationRequestProto.create = function create(properties) {
        return new GetNetworkConfigurationRequestProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationRequestProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationRequestProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {IGetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationRequestProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationRequestProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationRequestProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationRequestProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationRequestProto message.
     * @function verify
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationRequestProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationRequestProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationRequestProto} GetNetworkConfigurationRequestProto
     */
    GetNetworkConfigurationRequestProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationRequestProto)
            return object;
        return new $root.GetNetworkConfigurationRequestProto();
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationRequestProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {GetNetworkConfigurationRequestProto} message GetNetworkConfigurationRequestProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationRequestProto.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetNetworkConfigurationRequestProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationRequestProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationRequestProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationRequestProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationRequestProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationRequestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationRequestProto";
    };

    return GetNetworkConfigurationRequestProto;
})();

$root.GetNetworkConfigurationResponseProto = (function() {

    /**
     * Properties of a GetNetworkConfigurationResponseProto.
     * @exports IGetNetworkConfigurationResponseProto
     * @interface IGetNetworkConfigurationResponseProto
     * @property {boolean|null} [okay] GetNetworkConfigurationResponseProto okay
     * @property {string|null} [networkName] GetNetworkConfigurationResponseProto networkName
     * @property {string|null} [networkDescription] GetNetworkConfigurationResponseProto networkDescription
     * @property {string|null} [primaryColor] GetNetworkConfigurationResponseProto primaryColor
     * @property {string|null} [backgroundColor] GetNetworkConfigurationResponseProto backgroundColor
     * @property {string|null} [cardColor] GetNetworkConfigurationResponseProto cardColor
     */

    /**
     * Constructs a new GetNetworkConfigurationResponseProto.
     * @exports GetNetworkConfigurationResponseProto
     * @classdesc Represents a GetNetworkConfigurationResponseProto.
     * @implements IGetNetworkConfigurationResponseProto
     * @constructor
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     */
    function GetNetworkConfigurationResponseProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetNetworkConfigurationResponseProto okay.
     * @member {boolean} okay
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.okay = false;

    /**
     * GetNetworkConfigurationResponseProto networkName.
     * @member {string} networkName
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkName = "";

    /**
     * GetNetworkConfigurationResponseProto networkDescription.
     * @member {string} networkDescription
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.networkDescription = "";

    /**
     * GetNetworkConfigurationResponseProto primaryColor.
     * @member {string} primaryColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.primaryColor = "";

    /**
     * GetNetworkConfigurationResponseProto backgroundColor.
     * @member {string} backgroundColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.backgroundColor = "";

    /**
     * GetNetworkConfigurationResponseProto cardColor.
     * @member {string} cardColor
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     */
    GetNetworkConfigurationResponseProto.prototype.cardColor = "";

    /**
     * Creates a new GetNetworkConfigurationResponseProto instance using the specified properties.
     * @function create
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto=} [properties] Properties to set
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto instance
     */
    GetNetworkConfigurationResponseProto.create = function create(properties) {
        return new GetNetworkConfigurationResponseProto(properties);
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.networkName != null && Object.hasOwnProperty.call(message, "networkName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.networkName);
        if (message.networkDescription != null && Object.hasOwnProperty.call(message, "networkDescription"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.networkDescription);
        if (message.primaryColor != null && Object.hasOwnProperty.call(message, "primaryColor"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.primaryColor);
        if (message.backgroundColor != null && Object.hasOwnProperty.call(message, "backgroundColor"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.backgroundColor);
        if (message.cardColor != null && Object.hasOwnProperty.call(message, "cardColor"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.cardColor);
        return writer;
    };

    /**
     * Encodes the specified GetNetworkConfigurationResponseProto message, length delimited. Does not implicitly {@link GetNetworkConfigurationResponseProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {IGetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetNetworkConfigurationResponseProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer.
     * @function decode
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetNetworkConfigurationResponseProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.networkName = reader.string();
                    break;
                }
            case 3: {
                    message.networkDescription = reader.string();
                    break;
                }
            case 4: {
                    message.primaryColor = reader.string();
                    break;
                }
            case 5: {
                    message.backgroundColor = reader.string();
                    break;
                }
            case 6: {
                    message.cardColor = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetNetworkConfigurationResponseProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetNetworkConfigurationResponseProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetNetworkConfigurationResponseProto message.
     * @function verify
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetNetworkConfigurationResponseProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            if (!$util.isString(message.networkName))
                return "networkName: string expected";
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            if (!$util.isString(message.networkDescription))
                return "networkDescription: string expected";
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            if (!$util.isString(message.primaryColor))
                return "primaryColor: string expected";
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            if (!$util.isString(message.backgroundColor))
                return "backgroundColor: string expected";
        if (message.cardColor != null && message.hasOwnProperty("cardColor"))
            if (!$util.isString(message.cardColor))
                return "cardColor: string expected";
        return null;
    };

    /**
     * Creates a GetNetworkConfigurationResponseProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetNetworkConfigurationResponseProto} GetNetworkConfigurationResponseProto
     */
    GetNetworkConfigurationResponseProto.fromObject = function fromObject(object) {
        if (object instanceof $root.GetNetworkConfigurationResponseProto)
            return object;
        var message = new $root.GetNetworkConfigurationResponseProto();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.networkName != null)
            message.networkName = String(object.networkName);
        if (object.networkDescription != null)
            message.networkDescription = String(object.networkDescription);
        if (object.primaryColor != null)
            message.primaryColor = String(object.primaryColor);
        if (object.backgroundColor != null)
            message.backgroundColor = String(object.backgroundColor);
        if (object.cardColor != null)
            message.cardColor = String(object.cardColor);
        return message;
    };

    /**
     * Creates a plain object from a GetNetworkConfigurationResponseProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {GetNetworkConfigurationResponseProto} message GetNetworkConfigurationResponseProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetNetworkConfigurationResponseProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.networkName = "";
            object.networkDescription = "";
            object.primaryColor = "";
            object.backgroundColor = "";
            object.cardColor = "";
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.networkName != null && message.hasOwnProperty("networkName"))
            object.networkName = message.networkName;
        if (message.networkDescription != null && message.hasOwnProperty("networkDescription"))
            object.networkDescription = message.networkDescription;
        if (message.primaryColor != null && message.hasOwnProperty("primaryColor"))
            object.primaryColor = message.primaryColor;
        if (message.backgroundColor != null && message.hasOwnProperty("backgroundColor"))
            object.backgroundColor = message.backgroundColor;
        if (message.cardColor != null && message.hasOwnProperty("cardColor"))
            object.cardColor = message.cardColor;
        return object;
    };

    /**
     * Converts this GetNetworkConfigurationResponseProto to JSON.
     * @function toJSON
     * @memberof GetNetworkConfigurationResponseProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetNetworkConfigurationResponseProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetNetworkConfigurationResponseProto
     * @function getTypeUrl
     * @memberof GetNetworkConfigurationResponseProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetNetworkConfigurationResponseProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetNetworkConfigurationResponseProto";
    };

    return GetNetworkConfigurationResponseProto;
})();

$root.ProviderProto = (function() {

    /**
     * Properties of a ProviderProto.
     * @exports IProviderProto
     * @interface IProviderProto
     * @property {string|null} [providerId] ProviderProto providerId
     * @property {string|null} [name] ProviderProto name
     * @property {string|null} [description] ProviderProto description
     * @property {string|null} [address] ProviderProto address
     * @property {number|null} [lat] ProviderProto lat
     * @property {number|null} [lng] ProviderProto lng
     * @property {string|null} [shortUrl] ProviderProto shortUrl
     * @property {Array.<IProviderServiceProto>|null} [services] ProviderProto services
     */

    /**
     * Constructs a new ProviderProto.
     * @exports ProviderProto
     * @classdesc Represents a ProviderProto.
     * @implements IProviderProto
     * @constructor
     * @param {IProviderProto=} [properties] Properties to set
     */
    function ProviderProto(properties) {
        this.services = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderProto providerId.
     * @member {string} providerId
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.providerId = "";

    /**
     * ProviderProto name.
     * @member {string} name
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.name = "";

    /**
     * ProviderProto description.
     * @member {string} description
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.description = "";

    /**
     * ProviderProto address.
     * @member {string} address
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.address = "";

    /**
     * ProviderProto lat.
     * @member {number} lat
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.lat = 0;

    /**
     * ProviderProto lng.
     * @member {number} lng
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.lng = 0;

    /**
     * ProviderProto shortUrl.
     * @member {string} shortUrl
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.shortUrl = "";

    /**
     * ProviderProto services.
     * @member {Array.<IProviderServiceProto>} services
     * @memberof ProviderProto
     * @instance
     */
    ProviderProto.prototype.services = $util.emptyArray;

    /**
     * Creates a new ProviderProto instance using the specified properties.
     * @function create
     * @memberof ProviderProto
     * @static
     * @param {IProviderProto=} [properties] Properties to set
     * @returns {ProviderProto} ProviderProto instance
     */
    ProviderProto.create = function create(properties) {
        return new ProviderProto(properties);
    };

    /**
     * Encodes the specified ProviderProto message. Does not implicitly {@link ProviderProto.verify|verify} messages.
     * @function encode
     * @memberof ProviderProto
     * @static
     * @param {IProviderProto} message ProviderProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        if (message.address != null && Object.hasOwnProperty.call(message, "address"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.address);
        if (message.lat != null && Object.hasOwnProperty.call(message, "lat"))
            writer.uint32(/* id 6, wireType 1 =*/49).double(message.lat);
        if (message.lng != null && Object.hasOwnProperty.call(message, "lng"))
            writer.uint32(/* id 7, wireType 1 =*/57).double(message.lng);
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.shortUrl);
        if (message.services != null && message.services.length)
            for (var i = 0; i < message.services.length; ++i)
                $root.ProviderServiceProto.encode(message.services[i], writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified ProviderProto message, length delimited. Does not implicitly {@link ProviderProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderProto
     * @static
     * @param {IProviderProto} message ProviderProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderProto message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderProto} ProviderProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.description = reader.string();
                    break;
                }
            case 5: {
                    message.address = reader.string();
                    break;
                }
            case 6: {
                    message.lat = reader.double();
                    break;
                }
            case 7: {
                    message.lng = reader.double();
                    break;
                }
            case 8: {
                    message.shortUrl = reader.string();
                    break;
                }
            case 10: {
                    if (!(message.services && message.services.length))
                        message.services = [];
                    message.services.push($root.ProviderServiceProto.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderProto} ProviderProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderProto message.
     * @function verify
     * @memberof ProviderProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        if (message.address != null && message.hasOwnProperty("address"))
            if (!$util.isString(message.address))
                return "address: string expected";
        if (message.lat != null && message.hasOwnProperty("lat"))
            if (typeof message.lat !== "number")
                return "lat: number expected";
        if (message.lng != null && message.hasOwnProperty("lng"))
            if (typeof message.lng !== "number")
                return "lng: number expected";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        if (message.services != null && message.hasOwnProperty("services")) {
            if (!Array.isArray(message.services))
                return "services: array expected";
            for (var i = 0; i < message.services.length; ++i) {
                var error = $root.ProviderServiceProto.verify(message.services[i]);
                if (error)
                    return "services." + error;
            }
        }
        return null;
    };

    /**
     * Creates a ProviderProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderProto} ProviderProto
     */
    ProviderProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderProto)
            return object;
        var message = new $root.ProviderProto();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.name != null)
            message.name = String(object.name);
        if (object.description != null)
            message.description = String(object.description);
        if (object.address != null)
            message.address = String(object.address);
        if (object.lat != null)
            message.lat = Number(object.lat);
        if (object.lng != null)
            message.lng = Number(object.lng);
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        if (object.services) {
            if (!Array.isArray(object.services))
                throw TypeError(".ProviderProto.services: array expected");
            message.services = [];
            for (var i = 0; i < object.services.length; ++i) {
                if (typeof object.services[i] !== "object")
                    throw TypeError(".ProviderProto.services: object expected");
                message.services[i] = $root.ProviderServiceProto.fromObject(object.services[i]);
            }
        }
        return message;
    };

    /**
     * Creates a plain object from a ProviderProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderProto
     * @static
     * @param {ProviderProto} message ProviderProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.services = [];
        if (options.defaults) {
            object.providerId = "";
            object.name = "";
            object.description = "";
            object.address = "";
            object.lat = 0;
            object.lng = 0;
            object.shortUrl = "";
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.address != null && message.hasOwnProperty("address"))
            object.address = message.address;
        if (message.lat != null && message.hasOwnProperty("lat"))
            object.lat = options.json && !isFinite(message.lat) ? String(message.lat) : message.lat;
        if (message.lng != null && message.hasOwnProperty("lng"))
            object.lng = options.json && !isFinite(message.lng) ? String(message.lng) : message.lng;
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        if (message.services && message.services.length) {
            object.services = [];
            for (var j = 0; j < message.services.length; ++j)
                object.services[j] = $root.ProviderServiceProto.toObject(message.services[j], options);
        }
        return object;
    };

    /**
     * Converts this ProviderProto to JSON.
     * @function toJSON
     * @memberof ProviderProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderProto
     * @function getTypeUrl
     * @memberof ProviderProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderProto";
    };

    return ProviderProto;
})();

$root.FindProviderRequest = (function() {

    /**
     * Properties of a FindProviderRequest.
     * @exports IFindProviderRequest
     * @interface IFindProviderRequest
     * @property {string|null} [category] FindProviderRequest category
     * @property {number|null} [north] FindProviderRequest north
     * @property {number|null} [south] FindProviderRequest south
     * @property {number|null} [east] FindProviderRequest east
     * @property {number|null} [west] FindProviderRequest west
     * @property {number|null} [page] FindProviderRequest page
     */

    /**
     * Constructs a new FindProviderRequest.
     * @exports FindProviderRequest
     * @classdesc Represents a FindProviderRequest.
     * @implements IFindProviderRequest
     * @constructor
     * @param {IFindProviderRequest=} [properties] Properties to set
     */
    function FindProviderRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FindProviderRequest category.
     * @member {string} category
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.category = "";

    /**
     * FindProviderRequest north.
     * @member {number} north
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.north = 0;

    /**
     * FindProviderRequest south.
     * @member {number} south
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.south = 0;

    /**
     * FindProviderRequest east.
     * @member {number} east
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.east = 0;

    /**
     * FindProviderRequest west.
     * @member {number} west
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.west = 0;

    /**
     * FindProviderRequest page.
     * @member {number} page
     * @memberof FindProviderRequest
     * @instance
     */
    FindProviderRequest.prototype.page = 0;

    /**
     * Creates a new FindProviderRequest instance using the specified properties.
     * @function create
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest=} [properties] Properties to set
     * @returns {FindProviderRequest} FindProviderRequest instance
     */
    FindProviderRequest.create = function create(properties) {
        return new FindProviderRequest(properties);
    };

    /**
     * Encodes the specified FindProviderRequest message. Does not implicitly {@link FindProviderRequest.verify|verify} messages.
     * @function encode
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest} message FindProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.category != null && Object.hasOwnProperty.call(message, "category"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.category);
        if (message.north != null && Object.hasOwnProperty.call(message, "north"))
            writer.uint32(/* id 2, wireType 1 =*/17).double(message.north);
        if (message.south != null && Object.hasOwnProperty.call(message, "south"))
            writer.uint32(/* id 3, wireType 1 =*/25).double(message.south);
        if (message.east != null && Object.hasOwnProperty.call(message, "east"))
            writer.uint32(/* id 4, wireType 1 =*/33).double(message.east);
        if (message.west != null && Object.hasOwnProperty.call(message, "west"))
            writer.uint32(/* id 5, wireType 1 =*/41).double(message.west);
        if (message.page != null && Object.hasOwnProperty.call(message, "page"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.page);
        return writer;
    };

    /**
     * Encodes the specified FindProviderRequest message, length delimited. Does not implicitly {@link FindProviderRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FindProviderRequest
     * @static
     * @param {IFindProviderRequest} message FindProviderRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FindProviderRequest message from the specified reader or buffer.
     * @function decode
     * @memberof FindProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FindProviderRequest} FindProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FindProviderRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.category = reader.string();
                    break;
                }
            case 2: {
                    message.north = reader.double();
                    break;
                }
            case 3: {
                    message.south = reader.double();
                    break;
                }
            case 4: {
                    message.east = reader.double();
                    break;
                }
            case 5: {
                    message.west = reader.double();
                    break;
                }
            case 10: {
                    message.page = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FindProviderRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FindProviderRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FindProviderRequest} FindProviderRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FindProviderRequest message.
     * @function verify
     * @memberof FindProviderRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FindProviderRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.category != null && message.hasOwnProperty("category"))
            if (!$util.isString(message.category))
                return "category: string expected";
        if (message.north != null && message.hasOwnProperty("north"))
            if (typeof message.north !== "number")
                return "north: number expected";
        if (message.south != null && message.hasOwnProperty("south"))
            if (typeof message.south !== "number")
                return "south: number expected";
        if (message.east != null && message.hasOwnProperty("east"))
            if (typeof message.east !== "number")
                return "east: number expected";
        if (message.west != null && message.hasOwnProperty("west"))
            if (typeof message.west !== "number")
                return "west: number expected";
        if (message.page != null && message.hasOwnProperty("page"))
            if (!$util.isInteger(message.page))
                return "page: integer expected";
        return null;
    };

    /**
     * Creates a FindProviderRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FindProviderRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FindProviderRequest} FindProviderRequest
     */
    FindProviderRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.FindProviderRequest)
            return object;
        var message = new $root.FindProviderRequest();
        if (object.category != null)
            message.category = String(object.category);
        if (object.north != null)
            message.north = Number(object.north);
        if (object.south != null)
            message.south = Number(object.south);
        if (object.east != null)
            message.east = Number(object.east);
        if (object.west != null)
            message.west = Number(object.west);
        if (object.page != null)
            message.page = object.page | 0;
        return message;
    };

    /**
     * Creates a plain object from a FindProviderRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FindProviderRequest
     * @static
     * @param {FindProviderRequest} message FindProviderRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FindProviderRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.category = "";
            object.north = 0;
            object.south = 0;
            object.east = 0;
            object.west = 0;
            object.page = 0;
        }
        if (message.category != null && message.hasOwnProperty("category"))
            object.category = message.category;
        if (message.north != null && message.hasOwnProperty("north"))
            object.north = options.json && !isFinite(message.north) ? String(message.north) : message.north;
        if (message.south != null && message.hasOwnProperty("south"))
            object.south = options.json && !isFinite(message.south) ? String(message.south) : message.south;
        if (message.east != null && message.hasOwnProperty("east"))
            object.east = options.json && !isFinite(message.east) ? String(message.east) : message.east;
        if (message.west != null && message.hasOwnProperty("west"))
            object.west = options.json && !isFinite(message.west) ? String(message.west) : message.west;
        if (message.page != null && message.hasOwnProperty("page"))
            object.page = message.page;
        return object;
    };

    /**
     * Converts this FindProviderRequest to JSON.
     * @function toJSON
     * @memberof FindProviderRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FindProviderRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FindProviderRequest
     * @function getTypeUrl
     * @memberof FindProviderRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FindProviderRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FindProviderRequest";
    };

    return FindProviderRequest;
})();

$root.FindProviderResponse = (function() {

    /**
     * Properties of a FindProviderResponse.
     * @exports IFindProviderResponse
     * @interface IFindProviderResponse
     * @property {boolean|null} [okay] FindProviderResponse okay
     * @property {Array.<IProviderProto>|null} [providers] FindProviderResponse providers
     * @property {boolean|null} [more] FindProviderResponse more
     * @property {boolean|null} [viewportTooLarge] FindProviderResponse viewportTooLarge
     */

    /**
     * Constructs a new FindProviderResponse.
     * @exports FindProviderResponse
     * @classdesc Represents a FindProviderResponse.
     * @implements IFindProviderResponse
     * @constructor
     * @param {IFindProviderResponse=} [properties] Properties to set
     */
    function FindProviderResponse(properties) {
        this.providers = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * FindProviderResponse okay.
     * @member {boolean} okay
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.okay = false;

    /**
     * FindProviderResponse providers.
     * @member {Array.<IProviderProto>} providers
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.providers = $util.emptyArray;

    /**
     * FindProviderResponse more.
     * @member {boolean} more
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.more = false;

    /**
     * FindProviderResponse viewportTooLarge.
     * @member {boolean} viewportTooLarge
     * @memberof FindProviderResponse
     * @instance
     */
    FindProviderResponse.prototype.viewportTooLarge = false;

    /**
     * Creates a new FindProviderResponse instance using the specified properties.
     * @function create
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse=} [properties] Properties to set
     * @returns {FindProviderResponse} FindProviderResponse instance
     */
    FindProviderResponse.create = function create(properties) {
        return new FindProviderResponse(properties);
    };

    /**
     * Encodes the specified FindProviderResponse message. Does not implicitly {@link FindProviderResponse.verify|verify} messages.
     * @function encode
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse} message FindProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.providers != null && message.providers.length)
            for (var i = 0; i < message.providers.length; ++i)
                $root.ProviderProto.encode(message.providers[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.more != null && Object.hasOwnProperty.call(message, "more"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.more);
        if (message.viewportTooLarge != null && Object.hasOwnProperty.call(message, "viewportTooLarge"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.viewportTooLarge);
        return writer;
    };

    /**
     * Encodes the specified FindProviderResponse message, length delimited. Does not implicitly {@link FindProviderResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof FindProviderResponse
     * @static
     * @param {IFindProviderResponse} message FindProviderResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    FindProviderResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a FindProviderResponse message from the specified reader or buffer.
     * @function decode
     * @memberof FindProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {FindProviderResponse} FindProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.FindProviderResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.providers && message.providers.length))
                        message.providers = [];
                    message.providers.push($root.ProviderProto.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.more = reader.bool();
                    break;
                }
            case 4: {
                    message.viewportTooLarge = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a FindProviderResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof FindProviderResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {FindProviderResponse} FindProviderResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    FindProviderResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a FindProviderResponse message.
     * @function verify
     * @memberof FindProviderResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    FindProviderResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.providers != null && message.hasOwnProperty("providers")) {
            if (!Array.isArray(message.providers))
                return "providers: array expected";
            for (var i = 0; i < message.providers.length; ++i) {
                var error = $root.ProviderProto.verify(message.providers[i]);
                if (error)
                    return "providers." + error;
            }
        }
        if (message.more != null && message.hasOwnProperty("more"))
            if (typeof message.more !== "boolean")
                return "more: boolean expected";
        if (message.viewportTooLarge != null && message.hasOwnProperty("viewportTooLarge"))
            if (typeof message.viewportTooLarge !== "boolean")
                return "viewportTooLarge: boolean expected";
        return null;
    };

    /**
     * Creates a FindProviderResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof FindProviderResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {FindProviderResponse} FindProviderResponse
     */
    FindProviderResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.FindProviderResponse)
            return object;
        var message = new $root.FindProviderResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.providers) {
            if (!Array.isArray(object.providers))
                throw TypeError(".FindProviderResponse.providers: array expected");
            message.providers = [];
            for (var i = 0; i < object.providers.length; ++i) {
                if (typeof object.providers[i] !== "object")
                    throw TypeError(".FindProviderResponse.providers: object expected");
                message.providers[i] = $root.ProviderProto.fromObject(object.providers[i]);
            }
        }
        if (object.more != null)
            message.more = Boolean(object.more);
        if (object.viewportTooLarge != null)
            message.viewportTooLarge = Boolean(object.viewportTooLarge);
        return message;
    };

    /**
     * Creates a plain object from a FindProviderResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof FindProviderResponse
     * @static
     * @param {FindProviderResponse} message FindProviderResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    FindProviderResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.providers = [];
        if (options.defaults) {
            object.okay = false;
            object.more = false;
            object.viewportTooLarge = false;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.providers && message.providers.length) {
            object.providers = [];
            for (var j = 0; j < message.providers.length; ++j)
                object.providers[j] = $root.ProviderProto.toObject(message.providers[j], options);
        }
        if (message.more != null && message.hasOwnProperty("more"))
            object.more = message.more;
        if (message.viewportTooLarge != null && message.hasOwnProperty("viewportTooLarge"))
            object.viewportTooLarge = message.viewportTooLarge;
        return object;
    };

    /**
     * Converts this FindProviderResponse to JSON.
     * @function toJSON
     * @memberof FindProviderResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    FindProviderResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for FindProviderResponse
     * @function getTypeUrl
     * @memberof FindProviderResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    FindProviderResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/FindProviderResponse";
    };

    return FindProviderResponse;
})();

$root.GetProviderProfileRequest = (function() {

    /**
     * Properties of a GetProviderProfileRequest.
     * @exports IGetProviderProfileRequest
     * @interface IGetProviderProfileRequest
     * @property {string|null} [shortUrl] GetProviderProfileRequest shortUrl
     */

    /**
     * Constructs a new GetProviderProfileRequest.
     * @exports GetProviderProfileRequest
     * @classdesc Represents a GetProviderProfileRequest.
     * @implements IGetProviderProfileRequest
     * @constructor
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     */
    function GetProviderProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileRequest shortUrl.
     * @member {string} shortUrl
     * @memberof GetProviderProfileRequest
     * @instance
     */
    GetProviderProfileRequest.prototype.shortUrl = "";

    /**
     * Creates a new GetProviderProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest=} [properties] Properties to set
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest instance
     */
    GetProviderProfileRequest.create = function create(properties) {
        return new GetProviderProfileRequest(properties);
    };

    /**
     * Encodes the specified GetProviderProfileRequest message. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.shortUrl != null && Object.hasOwnProperty.call(message, "shortUrl"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.shortUrl);
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileRequest message, length delimited. Does not implicitly {@link GetProviderProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {IGetProviderProfileRequest} message GetProviderProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.shortUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileRequest message.
     * @function verify
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            if (!$util.isString(message.shortUrl))
                return "shortUrl: string expected";
        return null;
    };

    /**
     * Creates a GetProviderProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileRequest} GetProviderProfileRequest
     */
    GetProviderProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileRequest)
            return object;
        var message = new $root.GetProviderProfileRequest();
        if (object.shortUrl != null)
            message.shortUrl = String(object.shortUrl);
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileRequest
     * @static
     * @param {GetProviderProfileRequest} message GetProviderProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.shortUrl = "";
        if (message.shortUrl != null && message.hasOwnProperty("shortUrl"))
            object.shortUrl = message.shortUrl;
        return object;
    };

    /**
     * Converts this GetProviderProfileRequest to JSON.
     * @function toJSON
     * @memberof GetProviderProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileRequest
     * @function getTypeUrl
     * @memberof GetProviderProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileRequest";
    };

    return GetProviderProfileRequest;
})();

$root.GetProviderProfileResponse = (function() {

    /**
     * Properties of a GetProviderProfileResponse.
     * @exports IGetProviderProfileResponse
     * @interface IGetProviderProfileResponse
     * @property {boolean|null} [okay] GetProviderProfileResponse okay
     * @property {IProviderProto|null} [provider] GetProviderProfileResponse provider
     */

    /**
     * Constructs a new GetProviderProfileResponse.
     * @exports GetProviderProfileResponse
     * @classdesc Represents a GetProviderProfileResponse.
     * @implements IGetProviderProfileResponse
     * @constructor
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     */
    function GetProviderProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetProviderProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.okay = false;

    /**
     * GetProviderProfileResponse provider.
     * @member {IProviderProto|null|undefined} provider
     * @memberof GetProviderProfileResponse
     * @instance
     */
    GetProviderProfileResponse.prototype.provider = null;

    /**
     * Creates a new GetProviderProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse=} [properties] Properties to set
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse instance
     */
    GetProviderProfileResponse.create = function create(properties) {
        return new GetProviderProfileResponse(properties);
    };

    /**
     * Encodes the specified GetProviderProfileResponse message. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.provider != null && Object.hasOwnProperty.call(message, "provider"))
            $root.ProviderProto.encode(message.provider, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetProviderProfileResponse message, length delimited. Does not implicitly {@link GetProviderProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {IGetProviderProfileResponse} message GetProviderProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetProviderProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetProviderProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.provider = $root.ProviderProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetProviderProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetProviderProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetProviderProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetProviderProfileResponse message.
     * @function verify
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetProviderProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.provider != null && message.hasOwnProperty("provider")) {
            var error = $root.ProviderProto.verify(message.provider);
            if (error)
                return "provider." + error;
        }
        return null;
    };

    /**
     * Creates a GetProviderProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetProviderProfileResponse} GetProviderProfileResponse
     */
    GetProviderProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetProviderProfileResponse)
            return object;
        var message = new $root.GetProviderProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.provider != null) {
            if (typeof object.provider !== "object")
                throw TypeError(".GetProviderProfileResponse.provider: object expected");
            message.provider = $root.ProviderProto.fromObject(object.provider);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetProviderProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetProviderProfileResponse
     * @static
     * @param {GetProviderProfileResponse} message GetProviderProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetProviderProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.provider = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.provider != null && message.hasOwnProperty("provider"))
            object.provider = $root.ProviderProto.toObject(message.provider, options);
        return object;
    };

    /**
     * Converts this GetProviderProfileResponse to JSON.
     * @function toJSON
     * @memberof GetProviderProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetProviderProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetProviderProfileResponse
     * @function getTypeUrl
     * @memberof GetProviderProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetProviderProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetProviderProfileResponse";
    };

    return GetProviderProfileResponse;
})();

$root.ProviderServiceProto = (function() {

    /**
     * Properties of a ProviderServiceProto.
     * @exports IProviderServiceProto
     * @interface IProviderServiceProto
     * @property {string|null} [sku] ProviderServiceProto sku
     * @property {string|null} [name] ProviderServiceProto name
     * @property {string|null} [description] ProviderServiceProto description
     * @property {number|null} [lengthMinutes] ProviderServiceProto lengthMinutes
     * @property {number|null} [price] ProviderServiceProto price
     * @property {string|null} [currencyCode] ProviderServiceProto currencyCode
     * @property {string|null} [category] ProviderServiceProto category
     */

    /**
     * Constructs a new ProviderServiceProto.
     * @exports ProviderServiceProto
     * @classdesc Represents a ProviderServiceProto.
     * @implements IProviderServiceProto
     * @constructor
     * @param {IProviderServiceProto=} [properties] Properties to set
     */
    function ProviderServiceProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderServiceProto sku.
     * @member {string} sku
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.sku = "";

    /**
     * ProviderServiceProto name.
     * @member {string} name
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.name = "";

    /**
     * ProviderServiceProto description.
     * @member {string} description
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.description = "";

    /**
     * ProviderServiceProto lengthMinutes.
     * @member {number} lengthMinutes
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.lengthMinutes = 0;

    /**
     * ProviderServiceProto price.
     * @member {number} price
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.price = 0;

    /**
     * ProviderServiceProto currencyCode.
     * @member {string} currencyCode
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.currencyCode = "";

    /**
     * ProviderServiceProto category.
     * @member {string} category
     * @memberof ProviderServiceProto
     * @instance
     */
    ProviderServiceProto.prototype.category = "";

    /**
     * Creates a new ProviderServiceProto instance using the specified properties.
     * @function create
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto=} [properties] Properties to set
     * @returns {ProviderServiceProto} ProviderServiceProto instance
     */
    ProviderServiceProto.create = function create(properties) {
        return new ProviderServiceProto(properties);
    };

    /**
     * Encodes the specified ProviderServiceProto message. Does not implicitly {@link ProviderServiceProto.verify|verify} messages.
     * @function encode
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto} message ProviderServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderServiceProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
        if (message.description != null && Object.hasOwnProperty.call(message, "description"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.description);
        if (message.lengthMinutes != null && Object.hasOwnProperty.call(message, "lengthMinutes"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lengthMinutes);
        if (message.price != null && Object.hasOwnProperty.call(message, "price"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.price);
        if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.currencyCode);
        if (message.category != null && Object.hasOwnProperty.call(message, "category"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.category);
        return writer;
    };

    /**
     * Encodes the specified ProviderServiceProto message, length delimited. Does not implicitly {@link ProviderServiceProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ProviderServiceProto
     * @static
     * @param {IProviderServiceProto} message ProviderServiceProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ProviderServiceProto message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderServiceProto} ProviderServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderServiceProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderServiceProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.name = reader.string();
                    break;
                }
            case 3: {
                    message.description = reader.string();
                    break;
                }
            case 4: {
                    message.lengthMinutes = reader.int32();
                    break;
                }
            case 5: {
                    message.price = reader.int32();
                    break;
                }
            case 6: {
                    message.currencyCode = reader.string();
                    break;
                }
            case 7: {
                    message.category = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ProviderServiceProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ProviderServiceProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ProviderServiceProto} ProviderServiceProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderServiceProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ProviderServiceProto message.
     * @function verify
     * @memberof ProviderServiceProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ProviderServiceProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sku != null && message.hasOwnProperty("sku"))
            if (!$util.isString(message.sku))
                return "sku: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
            if (!$util.isString(message.name))
                return "name: string expected";
        if (message.description != null && message.hasOwnProperty("description"))
            if (!$util.isString(message.description))
                return "description: string expected";
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            if (!$util.isInteger(message.lengthMinutes))
                return "lengthMinutes: integer expected";
        if (message.price != null && message.hasOwnProperty("price"))
            if (!$util.isInteger(message.price))
                return "price: integer expected";
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            if (!$util.isString(message.currencyCode))
                return "currencyCode: string expected";
        if (message.category != null && message.hasOwnProperty("category"))
            if (!$util.isString(message.category))
                return "category: string expected";
        return null;
    };

    /**
     * Creates a ProviderServiceProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ProviderServiceProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ProviderServiceProto} ProviderServiceProto
     */
    ProviderServiceProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ProviderServiceProto)
            return object;
        var message = new $root.ProviderServiceProto();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.name != null)
            message.name = String(object.name);
        if (object.description != null)
            message.description = String(object.description);
        if (object.lengthMinutes != null)
            message.lengthMinutes = object.lengthMinutes | 0;
        if (object.price != null)
            message.price = object.price | 0;
        if (object.currencyCode != null)
            message.currencyCode = String(object.currencyCode);
        if (object.category != null)
            message.category = String(object.category);
        return message;
    };

    /**
     * Creates a plain object from a ProviderServiceProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ProviderServiceProto
     * @static
     * @param {ProviderServiceProto} message ProviderServiceProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ProviderServiceProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sku = "";
            object.name = "";
            object.description = "";
            object.lengthMinutes = 0;
            object.price = 0;
            object.currencyCode = "";
            object.category = "";
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.name != null && message.hasOwnProperty("name"))
            object.name = message.name;
        if (message.description != null && message.hasOwnProperty("description"))
            object.description = message.description;
        if (message.lengthMinutes != null && message.hasOwnProperty("lengthMinutes"))
            object.lengthMinutes = message.lengthMinutes;
        if (message.price != null && message.hasOwnProperty("price"))
            object.price = message.price;
        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
            object.currencyCode = message.currencyCode;
        if (message.category != null && message.hasOwnProperty("category"))
            object.category = message.category;
        return object;
    };

    /**
     * Converts this ProviderServiceProto to JSON.
     * @function toJSON
     * @memberof ProviderServiceProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ProviderServiceProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ProviderServiceProto
     * @function getTypeUrl
     * @memberof ProviderServiceProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ProviderServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ProviderServiceProto";
    };

    return ProviderServiceProto;
})();

$root.GetAvailableAppointmentsRequest = (function() {

    /**
     * Properties of a GetAvailableAppointmentsRequest.
     * @exports IGetAvailableAppointmentsRequest
     * @interface IGetAvailableAppointmentsRequest
     * @property {string|null} [sku] GetAvailableAppointmentsRequest sku
     * @property {string|null} [providerId] GetAvailableAppointmentsRequest providerId
     * @property {number|Long|null} [fromTimestampMs] GetAvailableAppointmentsRequest fromTimestampMs
     * @property {number|Long|null} [toTimestampMs] GetAvailableAppointmentsRequest toTimestampMs
     * @property {string|null} [timeZoneId] GetAvailableAppointmentsRequest timeZoneId
     */

    /**
     * Constructs a new GetAvailableAppointmentsRequest.
     * @exports GetAvailableAppointmentsRequest
     * @classdesc Represents a GetAvailableAppointmentsRequest.
     * @implements IGetAvailableAppointmentsRequest
     * @constructor
     * @param {IGetAvailableAppointmentsRequest=} [properties] Properties to set
     */
    function GetAvailableAppointmentsRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetAvailableAppointmentsRequest sku.
     * @member {string} sku
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.sku = "";

    /**
     * GetAvailableAppointmentsRequest providerId.
     * @member {string} providerId
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.providerId = "";

    /**
     * GetAvailableAppointmentsRequest fromTimestampMs.
     * @member {number|Long} fromTimestampMs
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.fromTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * GetAvailableAppointmentsRequest toTimestampMs.
     * @member {number|Long} toTimestampMs
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.toTimestampMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * GetAvailableAppointmentsRequest timeZoneId.
     * @member {string} timeZoneId
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     */
    GetAvailableAppointmentsRequest.prototype.timeZoneId = "";

    /**
     * Creates a new GetAvailableAppointmentsRequest instance using the specified properties.
     * @function create
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest=} [properties] Properties to set
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest instance
     */
    GetAvailableAppointmentsRequest.create = function create(properties) {
        return new GetAvailableAppointmentsRequest(properties);
    };

    /**
     * Encodes the specified GetAvailableAppointmentsRequest message. Does not implicitly {@link GetAvailableAppointmentsRequest.verify|verify} messages.
     * @function encode
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.sku != null && Object.hasOwnProperty.call(message, "sku"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.sku);
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.providerId);
        if (message.fromTimestampMs != null && Object.hasOwnProperty.call(message, "fromTimestampMs"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.fromTimestampMs);
        if (message.toTimestampMs != null && Object.hasOwnProperty.call(message, "toTimestampMs"))
            writer.uint32(/* id 4, wireType 0 =*/32).int64(message.toTimestampMs);
        if (message.timeZoneId != null && Object.hasOwnProperty.call(message, "timeZoneId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.timeZoneId);
        return writer;
    };

    /**
     * Encodes the specified GetAvailableAppointmentsRequest message, length delimited. Does not implicitly {@link GetAvailableAppointmentsRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {IGetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetAvailableAppointmentsRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetAvailableAppointmentsRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.sku = reader.string();
                    break;
                }
            case 2: {
                    message.providerId = reader.string();
                    break;
                }
            case 3: {
                    message.fromTimestampMs = reader.int64();
                    break;
                }
            case 4: {
                    message.toTimestampMs = reader.int64();
                    break;
                }
            case 5: {
                    message.timeZoneId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetAvailableAppointmentsRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetAvailableAppointmentsRequest message.
     * @function verify
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetAvailableAppointmentsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.sku != null && message.hasOwnProperty("sku"))
            if (!$util.isString(message.sku))
                return "sku: string expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.fromTimestampMs != null && message.hasOwnProperty("fromTimestampMs"))
            if (!$util.isInteger(message.fromTimestampMs) && !(message.fromTimestampMs && $util.isInteger(message.fromTimestampMs.low) && $util.isInteger(message.fromTimestampMs.high)))
                return "fromTimestampMs: integer|Long expected";
        if (message.toTimestampMs != null && message.hasOwnProperty("toTimestampMs"))
            if (!$util.isInteger(message.toTimestampMs) && !(message.toTimestampMs && $util.isInteger(message.toTimestampMs.low) && $util.isInteger(message.toTimestampMs.high)))
                return "toTimestampMs: integer|Long expected";
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            if (!$util.isString(message.timeZoneId))
                return "timeZoneId: string expected";
        return null;
    };

    /**
     * Creates a GetAvailableAppointmentsRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetAvailableAppointmentsRequest} GetAvailableAppointmentsRequest
     */
    GetAvailableAppointmentsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetAvailableAppointmentsRequest)
            return object;
        var message = new $root.GetAvailableAppointmentsRequest();
        if (object.sku != null)
            message.sku = String(object.sku);
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.fromTimestampMs != null)
            if ($util.Long)
                (message.fromTimestampMs = $util.Long.fromValue(object.fromTimestampMs)).unsigned = false;
            else if (typeof object.fromTimestampMs === "string")
                message.fromTimestampMs = parseInt(object.fromTimestampMs, 10);
            else if (typeof object.fromTimestampMs === "number")
                message.fromTimestampMs = object.fromTimestampMs;
            else if (typeof object.fromTimestampMs === "object")
                message.fromTimestampMs = new $util.LongBits(object.fromTimestampMs.low >>> 0, object.fromTimestampMs.high >>> 0).toNumber();
        if (object.toTimestampMs != null)
            if ($util.Long)
                (message.toTimestampMs = $util.Long.fromValue(object.toTimestampMs)).unsigned = false;
            else if (typeof object.toTimestampMs === "string")
                message.toTimestampMs = parseInt(object.toTimestampMs, 10);
            else if (typeof object.toTimestampMs === "number")
                message.toTimestampMs = object.toTimestampMs;
            else if (typeof object.toTimestampMs === "object")
                message.toTimestampMs = new $util.LongBits(object.toTimestampMs.low >>> 0, object.toTimestampMs.high >>> 0).toNumber();
        if (object.timeZoneId != null)
            message.timeZoneId = String(object.timeZoneId);
        return message;
    };

    /**
     * Creates a plain object from a GetAvailableAppointmentsRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {GetAvailableAppointmentsRequest} message GetAvailableAppointmentsRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetAvailableAppointmentsRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.sku = "";
            object.providerId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.fromTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.fromTimestampMs = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.toTimestampMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.toTimestampMs = options.longs === String ? "0" : 0;
            object.timeZoneId = "";
        }
        if (message.sku != null && message.hasOwnProperty("sku"))
            object.sku = message.sku;
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.fromTimestampMs != null && message.hasOwnProperty("fromTimestampMs"))
            if (typeof message.fromTimestampMs === "number")
                object.fromTimestampMs = options.longs === String ? String(message.fromTimestampMs) : message.fromTimestampMs;
            else
                object.fromTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.fromTimestampMs) : options.longs === Number ? new $util.LongBits(message.fromTimestampMs.low >>> 0, message.fromTimestampMs.high >>> 0).toNumber() : message.fromTimestampMs;
        if (message.toTimestampMs != null && message.hasOwnProperty("toTimestampMs"))
            if (typeof message.toTimestampMs === "number")
                object.toTimestampMs = options.longs === String ? String(message.toTimestampMs) : message.toTimestampMs;
            else
                object.toTimestampMs = options.longs === String ? $util.Long.prototype.toString.call(message.toTimestampMs) : options.longs === Number ? new $util.LongBits(message.toTimestampMs.low >>> 0, message.toTimestampMs.high >>> 0).toNumber() : message.toTimestampMs;
        if (message.timeZoneId != null && message.hasOwnProperty("timeZoneId"))
            object.timeZoneId = message.timeZoneId;
        return object;
    };

    /**
     * Converts this GetAvailableAppointmentsRequest to JSON.
     * @function toJSON
     * @memberof GetAvailableAppointmentsRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetAvailableAppointmentsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetAvailableAppointmentsRequest
     * @function getTypeUrl
     * @memberof GetAvailableAppointmentsRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetAvailableAppointmentsRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetAvailableAppointmentsRequest";
    };

    return GetAvailableAppointmentsRequest;
})();

$root.GetAvailableAppointmentsResponse = (function() {

    /**
     * Properties of a GetAvailableAppointmentsResponse.
     * @exports IGetAvailableAppointmentsResponse
     * @interface IGetAvailableAppointmentsResponse
     * @property {boolean|null} [okay] GetAvailableAppointmentsResponse okay
     * @property {Array.<number|Long>|null} [availableTimes] GetAvailableAppointmentsResponse availableTimes
     */

    /**
     * Constructs a new GetAvailableAppointmentsResponse.
     * @exports GetAvailableAppointmentsResponse
     * @classdesc Represents a GetAvailableAppointmentsResponse.
     * @implements IGetAvailableAppointmentsResponse
     * @constructor
     * @param {IGetAvailableAppointmentsResponse=} [properties] Properties to set
     */
    function GetAvailableAppointmentsResponse(properties) {
        this.availableTimes = [];
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetAvailableAppointmentsResponse okay.
     * @member {boolean} okay
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     */
    GetAvailableAppointmentsResponse.prototype.okay = false;

    /**
     * GetAvailableAppointmentsResponse availableTimes.
     * @member {Array.<number|Long>} availableTimes
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     */
    GetAvailableAppointmentsResponse.prototype.availableTimes = $util.emptyArray;

    /**
     * Creates a new GetAvailableAppointmentsResponse instance using the specified properties.
     * @function create
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse=} [properties] Properties to set
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse instance
     */
    GetAvailableAppointmentsResponse.create = function create(properties) {
        return new GetAvailableAppointmentsResponse(properties);
    };

    /**
     * Encodes the specified GetAvailableAppointmentsResponse message. Does not implicitly {@link GetAvailableAppointmentsResponse.verify|verify} messages.
     * @function encode
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.availableTimes != null && message.availableTimes.length) {
            writer.uint32(/* id 2, wireType 2 =*/18).fork();
            for (var i = 0; i < message.availableTimes.length; ++i)
                writer.int64(message.availableTimes[i]);
            writer.ldelim();
        }
        return writer;
    };

    /**
     * Encodes the specified GetAvailableAppointmentsResponse message, length delimited. Does not implicitly {@link GetAvailableAppointmentsResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {IGetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetAvailableAppointmentsResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetAvailableAppointmentsResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetAvailableAppointmentsResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    if (!(message.availableTimes && message.availableTimes.length))
                        message.availableTimes = [];
                    if ((tag & 7) === 2) {
                        var end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.availableTimes.push(reader.int64());
                    } else
                        message.availableTimes.push(reader.int64());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetAvailableAppointmentsResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetAvailableAppointmentsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetAvailableAppointmentsResponse message.
     * @function verify
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetAvailableAppointmentsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.availableTimes != null && message.hasOwnProperty("availableTimes")) {
            if (!Array.isArray(message.availableTimes))
                return "availableTimes: array expected";
            for (var i = 0; i < message.availableTimes.length; ++i)
                if (!$util.isInteger(message.availableTimes[i]) && !(message.availableTimes[i] && $util.isInteger(message.availableTimes[i].low) && $util.isInteger(message.availableTimes[i].high)))
                    return "availableTimes: integer|Long[] expected";
        }
        return null;
    };

    /**
     * Creates a GetAvailableAppointmentsResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetAvailableAppointmentsResponse} GetAvailableAppointmentsResponse
     */
    GetAvailableAppointmentsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetAvailableAppointmentsResponse)
            return object;
        var message = new $root.GetAvailableAppointmentsResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.availableTimes) {
            if (!Array.isArray(object.availableTimes))
                throw TypeError(".GetAvailableAppointmentsResponse.availableTimes: array expected");
            message.availableTimes = [];
            for (var i = 0; i < object.availableTimes.length; ++i)
                if ($util.Long)
                    (message.availableTimes[i] = $util.Long.fromValue(object.availableTimes[i])).unsigned = false;
                else if (typeof object.availableTimes[i] === "string")
                    message.availableTimes[i] = parseInt(object.availableTimes[i], 10);
                else if (typeof object.availableTimes[i] === "number")
                    message.availableTimes[i] = object.availableTimes[i];
                else if (typeof object.availableTimes[i] === "object")
                    message.availableTimes[i] = new $util.LongBits(object.availableTimes[i].low >>> 0, object.availableTimes[i].high >>> 0).toNumber();
        }
        return message;
    };

    /**
     * Creates a plain object from a GetAvailableAppointmentsResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {GetAvailableAppointmentsResponse} message GetAvailableAppointmentsResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetAvailableAppointmentsResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.arrays || options.defaults)
            object.availableTimes = [];
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.availableTimes && message.availableTimes.length) {
            object.availableTimes = [];
            for (var j = 0; j < message.availableTimes.length; ++j)
                if (typeof message.availableTimes[j] === "number")
                    object.availableTimes[j] = options.longs === String ? String(message.availableTimes[j]) : message.availableTimes[j];
                else
                    object.availableTimes[j] = options.longs === String ? $util.Long.prototype.toString.call(message.availableTimes[j]) : options.longs === Number ? new $util.LongBits(message.availableTimes[j].low >>> 0, message.availableTimes[j].high >>> 0).toNumber() : message.availableTimes[j];
        }
        return object;
    };

    /**
     * Converts this GetAvailableAppointmentsResponse to JSON.
     * @function toJSON
     * @memberof GetAvailableAppointmentsResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetAvailableAppointmentsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetAvailableAppointmentsResponse
     * @function getTypeUrl
     * @memberof GetAvailableAppointmentsResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetAvailableAppointmentsResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetAvailableAppointmentsResponse";
    };

    return GetAvailableAppointmentsResponse;
})();

$root.GetUserProfileRequest = (function() {

    /**
     * Properties of a GetUserProfileRequest.
     * @exports IGetUserProfileRequest
     * @interface IGetUserProfileRequest
     */

    /**
     * Constructs a new GetUserProfileRequest.
     * @exports GetUserProfileRequest
     * @classdesc Represents a GetUserProfileRequest.
     * @implements IGetUserProfileRequest
     * @constructor
     * @param {IGetUserProfileRequest=} [properties] Properties to set
     */
    function GetUserProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Creates a new GetUserProfileRequest instance using the specified properties.
     * @function create
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest=} [properties] Properties to set
     * @returns {GetUserProfileRequest} GetUserProfileRequest instance
     */
    GetUserProfileRequest.create = function create(properties) {
        return new GetUserProfileRequest(properties);
    };

    /**
     * Encodes the specified GetUserProfileRequest message. Does not implicitly {@link GetUserProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest} message GetUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Encodes the specified GetUserProfileRequest message, length delimited. Does not implicitly {@link GetUserProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetUserProfileRequest
     * @static
     * @param {IGetUserProfileRequest} message GetUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof GetUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetUserProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetUserProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProfileRequest message.
     * @function verify
     * @memberof GetUserProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        return null;
    };

    /**
     * Creates a GetUserProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetUserProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetUserProfileRequest} GetUserProfileRequest
     */
    GetUserProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.GetUserProfileRequest)
            return object;
        return new $root.GetUserProfileRequest();
    };

    /**
     * Creates a plain object from a GetUserProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetUserProfileRequest
     * @static
     * @param {GetUserProfileRequest} message GetUserProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProfileRequest.toObject = function toObject() {
        return {};
    };

    /**
     * Converts this GetUserProfileRequest to JSON.
     * @function toJSON
     * @memberof GetUserProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProfileRequest
     * @function getTypeUrl
     * @memberof GetUserProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetUserProfileRequest";
    };

    return GetUserProfileRequest;
})();

$root.GetUserProfileResponse = (function() {

    /**
     * Properties of a GetUserProfileResponse.
     * @exports IGetUserProfileResponse
     * @interface IGetUserProfileResponse
     * @property {boolean|null} [okay] GetUserProfileResponse okay
     * @property {IUserProfileProto|null} [profile] GetUserProfileResponse profile
     */

    /**
     * Constructs a new GetUserProfileResponse.
     * @exports GetUserProfileResponse
     * @classdesc Represents a GetUserProfileResponse.
     * @implements IGetUserProfileResponse
     * @constructor
     * @param {IGetUserProfileResponse=} [properties] Properties to set
     */
    function GetUserProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GetUserProfileResponse okay.
     * @member {boolean} okay
     * @memberof GetUserProfileResponse
     * @instance
     */
    GetUserProfileResponse.prototype.okay = false;

    /**
     * GetUserProfileResponse profile.
     * @member {IUserProfileProto|null|undefined} profile
     * @memberof GetUserProfileResponse
     * @instance
     */
    GetUserProfileResponse.prototype.profile = null;

    /**
     * Creates a new GetUserProfileResponse instance using the specified properties.
     * @function create
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse=} [properties] Properties to set
     * @returns {GetUserProfileResponse} GetUserProfileResponse instance
     */
    GetUserProfileResponse.create = function create(properties) {
        return new GetUserProfileResponse(properties);
    };

    /**
     * Encodes the specified GetUserProfileResponse message. Does not implicitly {@link GetUserProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse} message GetUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.UserProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified GetUserProfileResponse message, length delimited. Does not implicitly {@link GetUserProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof GetUserProfileResponse
     * @static
     * @param {IGetUserProfileResponse} message GetUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GetUserProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a GetUserProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof GetUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.GetUserProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a GetUserProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof GetUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GetUserProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a GetUserProfileResponse message.
     * @function verify
     * @memberof GetUserProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    GetUserProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.UserProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates a GetUserProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof GetUserProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {GetUserProfileResponse} GetUserProfileResponse
     */
    GetUserProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.GetUserProfileResponse)
            return object;
        var message = new $root.GetUserProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".GetUserProfileResponse.profile: object expected");
            message.profile = $root.UserProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from a GetUserProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof GetUserProfileResponse
     * @static
     * @param {GetUserProfileResponse} message GetUserProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    GetUserProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.UserProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this GetUserProfileResponse to JSON.
     * @function toJSON
     * @memberof GetUserProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    GetUserProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for GetUserProfileResponse
     * @function getTypeUrl
     * @memberof GetUserProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    GetUserProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/GetUserProfileResponse";
    };

    return GetUserProfileResponse;
})();

$root.UpdateUserProfileRequest = (function() {

    /**
     * Properties of an UpdateUserProfileRequest.
     * @exports IUpdateUserProfileRequest
     * @interface IUpdateUserProfileRequest
     * @property {IUserProfileProto|null} [profile] UpdateUserProfileRequest profile
     */

    /**
     * Constructs a new UpdateUserProfileRequest.
     * @exports UpdateUserProfileRequest
     * @classdesc Represents an UpdateUserProfileRequest.
     * @implements IUpdateUserProfileRequest
     * @constructor
     * @param {IUpdateUserProfileRequest=} [properties] Properties to set
     */
    function UpdateUserProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateUserProfileRequest profile.
     * @member {IUserProfileProto|null|undefined} profile
     * @memberof UpdateUserProfileRequest
     * @instance
     */
    UpdateUserProfileRequest.prototype.profile = null;

    /**
     * Creates a new UpdateUserProfileRequest instance using the specified properties.
     * @function create
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {IUpdateUserProfileRequest=} [properties] Properties to set
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest instance
     */
    UpdateUserProfileRequest.create = function create(properties) {
        return new UpdateUserProfileRequest(properties);
    };

    /**
     * Encodes the specified UpdateUserProfileRequest message. Does not implicitly {@link UpdateUserProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {IUpdateUserProfileRequest} message UpdateUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.UserProfileProto.encode(message.profile, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateUserProfileRequest message, length delimited. Does not implicitly {@link UpdateUserProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {IUpdateUserProfileRequest} message UpdateUserProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateUserProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateUserProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profile = $root.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateUserProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateUserProfileRequest message.
     * @function verify
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateUserProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.UserProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateUserProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateUserProfileRequest} UpdateUserProfileRequest
     */
    UpdateUserProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateUserProfileRequest)
            return object;
        var message = new $root.UpdateUserProfileRequest();
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".UpdateUserProfileRequest.profile: object expected");
            message.profile = $root.UserProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateUserProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {UpdateUserProfileRequest} message UpdateUserProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateUserProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.profile = null;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.UserProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this UpdateUserProfileRequest to JSON.
     * @function toJSON
     * @memberof UpdateUserProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateUserProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateUserProfileRequest
     * @function getTypeUrl
     * @memberof UpdateUserProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateUserProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateUserProfileRequest";
    };

    return UpdateUserProfileRequest;
})();

$root.UpdateUserProfileResponse = (function() {

    /**
     * Properties of an UpdateUserProfileResponse.
     * @exports IUpdateUserProfileResponse
     * @interface IUpdateUserProfileResponse
     * @property {boolean|null} [okay] UpdateUserProfileResponse okay
     * @property {IUserProfileProto|null} [updatedProfile] UpdateUserProfileResponse updatedProfile
     */

    /**
     * Constructs a new UpdateUserProfileResponse.
     * @exports UpdateUserProfileResponse
     * @classdesc Represents an UpdateUserProfileResponse.
     * @implements IUpdateUserProfileResponse
     * @constructor
     * @param {IUpdateUserProfileResponse=} [properties] Properties to set
     */
    function UpdateUserProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UpdateUserProfileResponse okay.
     * @member {boolean} okay
     * @memberof UpdateUserProfileResponse
     * @instance
     */
    UpdateUserProfileResponse.prototype.okay = false;

    /**
     * UpdateUserProfileResponse updatedProfile.
     * @member {IUserProfileProto|null|undefined} updatedProfile
     * @memberof UpdateUserProfileResponse
     * @instance
     */
    UpdateUserProfileResponse.prototype.updatedProfile = null;

    /**
     * Creates a new UpdateUserProfileResponse instance using the specified properties.
     * @function create
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {IUpdateUserProfileResponse=} [properties] Properties to set
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse instance
     */
    UpdateUserProfileResponse.create = function create(properties) {
        return new UpdateUserProfileResponse(properties);
    };

    /**
     * Encodes the specified UpdateUserProfileResponse message. Does not implicitly {@link UpdateUserProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {IUpdateUserProfileResponse} message UpdateUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile"))
            $root.UserProfileProto.encode(message.updatedProfile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified UpdateUserProfileResponse message, length delimited. Does not implicitly {@link UpdateUserProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {IUpdateUserProfileResponse} message UpdateUserProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UpdateUserProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an UpdateUserProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.UpdateUserProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.updatedProfile = $root.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an UpdateUserProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UpdateUserProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an UpdateUserProfileResponse message.
     * @function verify
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    UpdateUserProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            var error = $root.UserProfileProto.verify(message.updatedProfile);
            if (error)
                return "updatedProfile." + error;
        }
        return null;
    };

    /**
     * Creates an UpdateUserProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {UpdateUserProfileResponse} UpdateUserProfileResponse
     */
    UpdateUserProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.UpdateUserProfileResponse)
            return object;
        var message = new $root.UpdateUserProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object")
                throw TypeError(".UpdateUserProfileResponse.updatedProfile: object expected");
            message.updatedProfile = $root.UserProfileProto.fromObject(object.updatedProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from an UpdateUserProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {UpdateUserProfileResponse} message UpdateUserProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    UpdateUserProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.updatedProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile"))
            object.updatedProfile = $root.UserProfileProto.toObject(message.updatedProfile, options);
        return object;
    };

    /**
     * Converts this UpdateUserProfileResponse to JSON.
     * @function toJSON
     * @memberof UpdateUserProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    UpdateUserProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for UpdateUserProfileResponse
     * @function getTypeUrl
     * @memberof UpdateUserProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    UpdateUserProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/UpdateUserProfileResponse";
    };

    return UpdateUserProfileResponse;
})();

$root.AcceptInvitationRequest = (function() {

    /**
     * Properties of an AcceptInvitationRequest.
     * @exports IAcceptInvitationRequest
     * @interface IAcceptInvitationRequest
     * @property {string|null} [inviteCode] AcceptInvitationRequest inviteCode
     * @property {boolean|null} [accepted] AcceptInvitationRequest accepted
     */

    /**
     * Constructs a new AcceptInvitationRequest.
     * @exports AcceptInvitationRequest
     * @classdesc Represents an AcceptInvitationRequest.
     * @implements IAcceptInvitationRequest
     * @constructor
     * @param {IAcceptInvitationRequest=} [properties] Properties to set
     */
    function AcceptInvitationRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptInvitationRequest inviteCode.
     * @member {string} inviteCode
     * @memberof AcceptInvitationRequest
     * @instance
     */
    AcceptInvitationRequest.prototype.inviteCode = "";

    /**
     * AcceptInvitationRequest accepted.
     * @member {boolean} accepted
     * @memberof AcceptInvitationRequest
     * @instance
     */
    AcceptInvitationRequest.prototype.accepted = false;

    /**
     * Creates a new AcceptInvitationRequest instance using the specified properties.
     * @function create
     * @memberof AcceptInvitationRequest
     * @static
     * @param {IAcceptInvitationRequest=} [properties] Properties to set
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest instance
     */
    AcceptInvitationRequest.create = function create(properties) {
        return new AcceptInvitationRequest(properties);
    };

    /**
     * Encodes the specified AcceptInvitationRequest message. Does not implicitly {@link AcceptInvitationRequest.verify|verify} messages.
     * @function encode
     * @memberof AcceptInvitationRequest
     * @static
     * @param {IAcceptInvitationRequest} message AcceptInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.inviteCode);
        if (message.accepted != null && Object.hasOwnProperty.call(message, "accepted"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.accepted);
        return writer;
    };

    /**
     * Encodes the specified AcceptInvitationRequest message, length delimited. Does not implicitly {@link AcceptInvitationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptInvitationRequest
     * @static
     * @param {IAcceptInvitationRequest} message AcceptInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptInvitationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptInvitationRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.inviteCode = reader.string();
                    break;
                }
            case 2: {
                    message.accepted = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptInvitationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptInvitationRequest message.
     * @function verify
     * @memberof AcceptInvitationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptInvitationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            if (typeof message.accepted !== "boolean")
                return "accepted: boolean expected";
        return null;
    };

    /**
     * Creates an AcceptInvitationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptInvitationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptInvitationRequest} AcceptInvitationRequest
     */
    AcceptInvitationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptInvitationRequest)
            return object;
        var message = new $root.AcceptInvitationRequest();
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        if (object.accepted != null)
            message.accepted = Boolean(object.accepted);
        return message;
    };

    /**
     * Creates a plain object from an AcceptInvitationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptInvitationRequest
     * @static
     * @param {AcceptInvitationRequest} message AcceptInvitationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptInvitationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.inviteCode = "";
            object.accepted = false;
        }
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        if (message.accepted != null && message.hasOwnProperty("accepted"))
            object.accepted = message.accepted;
        return object;
    };

    /**
     * Converts this AcceptInvitationRequest to JSON.
     * @function toJSON
     * @memberof AcceptInvitationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptInvitationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptInvitationRequest
     * @function getTypeUrl
     * @memberof AcceptInvitationRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptInvitationRequest";
    };

    return AcceptInvitationRequest;
})();

$root.AcceptInvitationResponse = (function() {

    /**
     * Properties of an AcceptInvitationResponse.
     * @exports IAcceptInvitationResponse
     * @interface IAcceptInvitationResponse
     * @property {boolean|null} [okay] AcceptInvitationResponse okay
     * @property {IUserProfileProto|null} [profile] AcceptInvitationResponse profile
     */

    /**
     * Constructs a new AcceptInvitationResponse.
     * @exports AcceptInvitationResponse
     * @classdesc Represents an AcceptInvitationResponse.
     * @implements IAcceptInvitationResponse
     * @constructor
     * @param {IAcceptInvitationResponse=} [properties] Properties to set
     */
    function AcceptInvitationResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AcceptInvitationResponse okay.
     * @member {boolean} okay
     * @memberof AcceptInvitationResponse
     * @instance
     */
    AcceptInvitationResponse.prototype.okay = false;

    /**
     * AcceptInvitationResponse profile.
     * @member {IUserProfileProto|null|undefined} profile
     * @memberof AcceptInvitationResponse
     * @instance
     */
    AcceptInvitationResponse.prototype.profile = null;

    /**
     * Creates a new AcceptInvitationResponse instance using the specified properties.
     * @function create
     * @memberof AcceptInvitationResponse
     * @static
     * @param {IAcceptInvitationResponse=} [properties] Properties to set
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse instance
     */
    AcceptInvitationResponse.create = function create(properties) {
        return new AcceptInvitationResponse(properties);
    };

    /**
     * Encodes the specified AcceptInvitationResponse message. Does not implicitly {@link AcceptInvitationResponse.verify|verify} messages.
     * @function encode
     * @memberof AcceptInvitationResponse
     * @static
     * @param {IAcceptInvitationResponse} message AcceptInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.profile != null && Object.hasOwnProperty.call(message, "profile"))
            $root.UserProfileProto.encode(message.profile, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified AcceptInvitationResponse message, length delimited. Does not implicitly {@link AcceptInvitationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof AcceptInvitationResponse
     * @static
     * @param {IAcceptInvitationResponse} message AcceptInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AcceptInvitationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes an AcceptInvitationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof AcceptInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.AcceptInvitationResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.profile = $root.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes an AcceptInvitationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof AcceptInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AcceptInvitationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies an AcceptInvitationResponse message.
     * @function verify
     * @memberof AcceptInvitationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    AcceptInvitationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.profile != null && message.hasOwnProperty("profile")) {
            var error = $root.UserProfileProto.verify(message.profile);
            if (error)
                return "profile." + error;
        }
        return null;
    };

    /**
     * Creates an AcceptInvitationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof AcceptInvitationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {AcceptInvitationResponse} AcceptInvitationResponse
     */
    AcceptInvitationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.AcceptInvitationResponse)
            return object;
        var message = new $root.AcceptInvitationResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.profile != null) {
            if (typeof object.profile !== "object")
                throw TypeError(".AcceptInvitationResponse.profile: object expected");
            message.profile = $root.UserProfileProto.fromObject(object.profile);
        }
        return message;
    };

    /**
     * Creates a plain object from an AcceptInvitationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof AcceptInvitationResponse
     * @static
     * @param {AcceptInvitationResponse} message AcceptInvitationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    AcceptInvitationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.profile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.profile != null && message.hasOwnProperty("profile"))
            object.profile = $root.UserProfileProto.toObject(message.profile, options);
        return object;
    };

    /**
     * Converts this AcceptInvitationResponse to JSON.
     * @function toJSON
     * @memberof AcceptInvitationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    AcceptInvitationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for AcceptInvitationResponse
     * @function getTypeUrl
     * @memberof AcceptInvitationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    AcceptInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/AcceptInvitationResponse";
    };

    return AcceptInvitationResponse;
})();

$root.CheckInvitationRequest = (function() {

    /**
     * Properties of a CheckInvitationRequest.
     * @exports ICheckInvitationRequest
     * @interface ICheckInvitationRequest
     * @property {string|null} [networkId] CheckInvitationRequest networkId
     * @property {string|null} [inviteCode] CheckInvitationRequest inviteCode
     */

    /**
     * Constructs a new CheckInvitationRequest.
     * @exports CheckInvitationRequest
     * @classdesc Represents a CheckInvitationRequest.
     * @implements ICheckInvitationRequest
     * @constructor
     * @param {ICheckInvitationRequest=} [properties] Properties to set
     */
    function CheckInvitationRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckInvitationRequest networkId.
     * @member {string} networkId
     * @memberof CheckInvitationRequest
     * @instance
     */
    CheckInvitationRequest.prototype.networkId = "";

    /**
     * CheckInvitationRequest inviteCode.
     * @member {string} inviteCode
     * @memberof CheckInvitationRequest
     * @instance
     */
    CheckInvitationRequest.prototype.inviteCode = "";

    /**
     * Creates a new CheckInvitationRequest instance using the specified properties.
     * @function create
     * @memberof CheckInvitationRequest
     * @static
     * @param {ICheckInvitationRequest=} [properties] Properties to set
     * @returns {CheckInvitationRequest} CheckInvitationRequest instance
     */
    CheckInvitationRequest.create = function create(properties) {
        return new CheckInvitationRequest(properties);
    };

    /**
     * Encodes the specified CheckInvitationRequest message. Does not implicitly {@link CheckInvitationRequest.verify|verify} messages.
     * @function encode
     * @memberof CheckInvitationRequest
     * @static
     * @param {ICheckInvitationRequest} message CheckInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.networkId != null && Object.hasOwnProperty.call(message, "networkId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.networkId);
        if (message.inviteCode != null && Object.hasOwnProperty.call(message, "inviteCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.inviteCode);
        return writer;
    };

    /**
     * Encodes the specified CheckInvitationRequest message, length delimited. Does not implicitly {@link CheckInvitationRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckInvitationRequest
     * @static
     * @param {ICheckInvitationRequest} message CheckInvitationRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckInvitationRequest message from the specified reader or buffer.
     * @function decode
     * @memberof CheckInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckInvitationRequest} CheckInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckInvitationRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.networkId = reader.string();
                    break;
                }
            case 2: {
                    message.inviteCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckInvitationRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckInvitationRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckInvitationRequest} CheckInvitationRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckInvitationRequest message.
     * @function verify
     * @memberof CheckInvitationRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckInvitationRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            if (!$util.isString(message.networkId))
                return "networkId: string expected";
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            if (!$util.isString(message.inviteCode))
                return "inviteCode: string expected";
        return null;
    };

    /**
     * Creates a CheckInvitationRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckInvitationRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckInvitationRequest} CheckInvitationRequest
     */
    CheckInvitationRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckInvitationRequest)
            return object;
        var message = new $root.CheckInvitationRequest();
        if (object.networkId != null)
            message.networkId = String(object.networkId);
        if (object.inviteCode != null)
            message.inviteCode = String(object.inviteCode);
        return message;
    };

    /**
     * Creates a plain object from a CheckInvitationRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckInvitationRequest
     * @static
     * @param {CheckInvitationRequest} message CheckInvitationRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckInvitationRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.networkId = "";
            object.inviteCode = "";
        }
        if (message.networkId != null && message.hasOwnProperty("networkId"))
            object.networkId = message.networkId;
        if (message.inviteCode != null && message.hasOwnProperty("inviteCode"))
            object.inviteCode = message.inviteCode;
        return object;
    };

    /**
     * Converts this CheckInvitationRequest to JSON.
     * @function toJSON
     * @memberof CheckInvitationRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckInvitationRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckInvitationRequest
     * @function getTypeUrl
     * @memberof CheckInvitationRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckInvitationRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckInvitationRequest";
    };

    return CheckInvitationRequest;
})();

$root.CheckInvitationResponse = (function() {

    /**
     * Properties of a CheckInvitationResponse.
     * @exports ICheckInvitationResponse
     * @interface ICheckInvitationResponse
     * @property {boolean|null} [okay] CheckInvitationResponse okay
     */

    /**
     * Constructs a new CheckInvitationResponse.
     * @exports CheckInvitationResponse
     * @classdesc Represents a CheckInvitationResponse.
     * @implements ICheckInvitationResponse
     * @constructor
     * @param {ICheckInvitationResponse=} [properties] Properties to set
     */
    function CheckInvitationResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CheckInvitationResponse okay.
     * @member {boolean} okay
     * @memberof CheckInvitationResponse
     * @instance
     */
    CheckInvitationResponse.prototype.okay = false;

    /**
     * Creates a new CheckInvitationResponse instance using the specified properties.
     * @function create
     * @memberof CheckInvitationResponse
     * @static
     * @param {ICheckInvitationResponse=} [properties] Properties to set
     * @returns {CheckInvitationResponse} CheckInvitationResponse instance
     */
    CheckInvitationResponse.create = function create(properties) {
        return new CheckInvitationResponse(properties);
    };

    /**
     * Encodes the specified CheckInvitationResponse message. Does not implicitly {@link CheckInvitationResponse.verify|verify} messages.
     * @function encode
     * @memberof CheckInvitationResponse
     * @static
     * @param {ICheckInvitationResponse} message CheckInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified CheckInvitationResponse message, length delimited. Does not implicitly {@link CheckInvitationResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof CheckInvitationResponse
     * @static
     * @param {ICheckInvitationResponse} message CheckInvitationResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CheckInvitationResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a CheckInvitationResponse message from the specified reader or buffer.
     * @function decode
     * @memberof CheckInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CheckInvitationResponse} CheckInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.CheckInvitationResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a CheckInvitationResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof CheckInvitationResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {CheckInvitationResponse} CheckInvitationResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CheckInvitationResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a CheckInvitationResponse message.
     * @function verify
     * @memberof CheckInvitationResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    CheckInvitationResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a CheckInvitationResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof CheckInvitationResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {CheckInvitationResponse} CheckInvitationResponse
     */
    CheckInvitationResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.CheckInvitationResponse)
            return object;
        var message = new $root.CheckInvitationResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a CheckInvitationResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof CheckInvitationResponse
     * @static
     * @param {CheckInvitationResponse} message CheckInvitationResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    CheckInvitationResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this CheckInvitationResponse to JSON.
     * @function toJSON
     * @memberof CheckInvitationResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    CheckInvitationResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for CheckInvitationResponse
     * @function getTypeUrl
     * @memberof CheckInvitationResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    CheckInvitationResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/CheckInvitationResponse";
    };

    return CheckInvitationResponse;
})();

$root.DeleteProfileRequest = (function() {

    /**
     * Properties of a DeleteProfileRequest.
     * @exports IDeleteProfileRequest
     * @interface IDeleteProfileRequest
     * @property {string|null} [profileId] DeleteProfileRequest profileId
     */

    /**
     * Constructs a new DeleteProfileRequest.
     * @exports DeleteProfileRequest
     * @classdesc Represents a DeleteProfileRequest.
     * @implements IDeleteProfileRequest
     * @constructor
     * @param {IDeleteProfileRequest=} [properties] Properties to set
     */
    function DeleteProfileRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DeleteProfileRequest profileId.
     * @member {string} profileId
     * @memberof DeleteProfileRequest
     * @instance
     */
    DeleteProfileRequest.prototype.profileId = "";

    /**
     * Creates a new DeleteProfileRequest instance using the specified properties.
     * @function create
     * @memberof DeleteProfileRequest
     * @static
     * @param {IDeleteProfileRequest=} [properties] Properties to set
     * @returns {DeleteProfileRequest} DeleteProfileRequest instance
     */
    DeleteProfileRequest.create = function create(properties) {
        return new DeleteProfileRequest(properties);
    };

    /**
     * Encodes the specified DeleteProfileRequest message. Does not implicitly {@link DeleteProfileRequest.verify|verify} messages.
     * @function encode
     * @memberof DeleteProfileRequest
     * @static
     * @param {IDeleteProfileRequest} message DeleteProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.profileId != null && Object.hasOwnProperty.call(message, "profileId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.profileId);
        return writer;
    };

    /**
     * Encodes the specified DeleteProfileRequest message, length delimited. Does not implicitly {@link DeleteProfileRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DeleteProfileRequest
     * @static
     * @param {IDeleteProfileRequest} message DeleteProfileRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DeleteProfileRequest message from the specified reader or buffer.
     * @function decode
     * @memberof DeleteProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DeleteProfileRequest} DeleteProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeleteProfileRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.profileId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DeleteProfileRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DeleteProfileRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DeleteProfileRequest} DeleteProfileRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DeleteProfileRequest message.
     * @function verify
     * @memberof DeleteProfileRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DeleteProfileRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            if (!$util.isString(message.profileId))
                return "profileId: string expected";
        return null;
    };

    /**
     * Creates a DeleteProfileRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DeleteProfileRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DeleteProfileRequest} DeleteProfileRequest
     */
    DeleteProfileRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.DeleteProfileRequest)
            return object;
        var message = new $root.DeleteProfileRequest();
        if (object.profileId != null)
            message.profileId = String(object.profileId);
        return message;
    };

    /**
     * Creates a plain object from a DeleteProfileRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DeleteProfileRequest
     * @static
     * @param {DeleteProfileRequest} message DeleteProfileRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DeleteProfileRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.profileId = "";
        if (message.profileId != null && message.hasOwnProperty("profileId"))
            object.profileId = message.profileId;
        return object;
    };

    /**
     * Converts this DeleteProfileRequest to JSON.
     * @function toJSON
     * @memberof DeleteProfileRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DeleteProfileRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DeleteProfileRequest
     * @function getTypeUrl
     * @memberof DeleteProfileRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DeleteProfileRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeleteProfileRequest";
    };

    return DeleteProfileRequest;
})();

$root.DeleteProfileResponse = (function() {

    /**
     * Properties of a DeleteProfileResponse.
     * @exports IDeleteProfileResponse
     * @interface IDeleteProfileResponse
     * @property {boolean|null} [okay] DeleteProfileResponse okay
     */

    /**
     * Constructs a new DeleteProfileResponse.
     * @exports DeleteProfileResponse
     * @classdesc Represents a DeleteProfileResponse.
     * @implements IDeleteProfileResponse
     * @constructor
     * @param {IDeleteProfileResponse=} [properties] Properties to set
     */
    function DeleteProfileResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DeleteProfileResponse okay.
     * @member {boolean} okay
     * @memberof DeleteProfileResponse
     * @instance
     */
    DeleteProfileResponse.prototype.okay = false;

    /**
     * Creates a new DeleteProfileResponse instance using the specified properties.
     * @function create
     * @memberof DeleteProfileResponse
     * @static
     * @param {IDeleteProfileResponse=} [properties] Properties to set
     * @returns {DeleteProfileResponse} DeleteProfileResponse instance
     */
    DeleteProfileResponse.create = function create(properties) {
        return new DeleteProfileResponse(properties);
    };

    /**
     * Encodes the specified DeleteProfileResponse message. Does not implicitly {@link DeleteProfileResponse.verify|verify} messages.
     * @function encode
     * @memberof DeleteProfileResponse
     * @static
     * @param {IDeleteProfileResponse} message DeleteProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        return writer;
    };

    /**
     * Encodes the specified DeleteProfileResponse message, length delimited. Does not implicitly {@link DeleteProfileResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof DeleteProfileResponse
     * @static
     * @param {IDeleteProfileResponse} message DeleteProfileResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DeleteProfileResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a DeleteProfileResponse message from the specified reader or buffer.
     * @function decode
     * @memberof DeleteProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DeleteProfileResponse} DeleteProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.DeleteProfileResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a DeleteProfileResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof DeleteProfileResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {DeleteProfileResponse} DeleteProfileResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DeleteProfileResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a DeleteProfileResponse message.
     * @function verify
     * @memberof DeleteProfileResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    DeleteProfileResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        return null;
    };

    /**
     * Creates a DeleteProfileResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof DeleteProfileResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {DeleteProfileResponse} DeleteProfileResponse
     */
    DeleteProfileResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.DeleteProfileResponse)
            return object;
        var message = new $root.DeleteProfileResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        return message;
    };

    /**
     * Creates a plain object from a DeleteProfileResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof DeleteProfileResponse
     * @static
     * @param {DeleteProfileResponse} message DeleteProfileResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    DeleteProfileResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults)
            object.okay = false;
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        return object;
    };

    /**
     * Converts this DeleteProfileResponse to JSON.
     * @function toJSON
     * @memberof DeleteProfileResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    DeleteProfileResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for DeleteProfileResponse
     * @function getTypeUrl
     * @memberof DeleteProfileResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    DeleteProfileResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/DeleteProfileResponse";
    };

    return DeleteProfileResponse;
})();

$root.BookAppointmentRequest = (function() {

    /**
     * Properties of a BookAppointmentRequest.
     * @exports IBookAppointmentRequest
     * @interface IBookAppointmentRequest
     * @property {string|null} [providerId] BookAppointmentRequest providerId
     * @property {string|null} [serviceId] BookAppointmentRequest serviceId
     * @property {number|Long|null} [appointmentTime] BookAppointmentRequest appointmentTime
     */

    /**
     * Constructs a new BookAppointmentRequest.
     * @exports BookAppointmentRequest
     * @classdesc Represents a BookAppointmentRequest.
     * @implements IBookAppointmentRequest
     * @constructor
     * @param {IBookAppointmentRequest=} [properties] Properties to set
     */
    function BookAppointmentRequest(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BookAppointmentRequest providerId.
     * @member {string} providerId
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.providerId = "";

    /**
     * BookAppointmentRequest serviceId.
     * @member {string} serviceId
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.serviceId = "";

    /**
     * BookAppointmentRequest appointmentTime.
     * @member {number|Long} appointmentTime
     * @memberof BookAppointmentRequest
     * @instance
     */
    BookAppointmentRequest.prototype.appointmentTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * Creates a new BookAppointmentRequest instance using the specified properties.
     * @function create
     * @memberof BookAppointmentRequest
     * @static
     * @param {IBookAppointmentRequest=} [properties] Properties to set
     * @returns {BookAppointmentRequest} BookAppointmentRequest instance
     */
    BookAppointmentRequest.create = function create(properties) {
        return new BookAppointmentRequest(properties);
    };

    /**
     * Encodes the specified BookAppointmentRequest message. Does not implicitly {@link BookAppointmentRequest.verify|verify} messages.
     * @function encode
     * @memberof BookAppointmentRequest
     * @static
     * @param {IBookAppointmentRequest} message BookAppointmentRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentRequest.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.providerId != null && Object.hasOwnProperty.call(message, "providerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.providerId);
        if (message.serviceId != null && Object.hasOwnProperty.call(message, "serviceId"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.serviceId);
        if (message.appointmentTime != null && Object.hasOwnProperty.call(message, "appointmentTime"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.appointmentTime);
        return writer;
    };

    /**
     * Encodes the specified BookAppointmentRequest message, length delimited. Does not implicitly {@link BookAppointmentRequest.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BookAppointmentRequest
     * @static
     * @param {IBookAppointmentRequest} message BookAppointmentRequest message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BookAppointmentRequest message from the specified reader or buffer.
     * @function decode
     * @memberof BookAppointmentRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BookAppointmentRequest} BookAppointmentRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.BookAppointmentRequest();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.providerId = reader.string();
                    break;
                }
            case 2: {
                    message.serviceId = reader.string();
                    break;
                }
            case 3: {
                    message.appointmentTime = reader.int64();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BookAppointmentRequest message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BookAppointmentRequest
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BookAppointmentRequest} BookAppointmentRequest
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BookAppointmentRequest message.
     * @function verify
     * @memberof BookAppointmentRequest
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BookAppointmentRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            if (!$util.isString(message.providerId))
                return "providerId: string expected";
        if (message.serviceId != null && message.hasOwnProperty("serviceId"))
            if (!$util.isString(message.serviceId))
                return "serviceId: string expected";
        if (message.appointmentTime != null && message.hasOwnProperty("appointmentTime"))
            if (!$util.isInteger(message.appointmentTime) && !(message.appointmentTime && $util.isInteger(message.appointmentTime.low) && $util.isInteger(message.appointmentTime.high)))
                return "appointmentTime: integer|Long expected";
        return null;
    };

    /**
     * Creates a BookAppointmentRequest message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BookAppointmentRequest
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BookAppointmentRequest} BookAppointmentRequest
     */
    BookAppointmentRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.BookAppointmentRequest)
            return object;
        var message = new $root.BookAppointmentRequest();
        if (object.providerId != null)
            message.providerId = String(object.providerId);
        if (object.serviceId != null)
            message.serviceId = String(object.serviceId);
        if (object.appointmentTime != null)
            if ($util.Long)
                (message.appointmentTime = $util.Long.fromValue(object.appointmentTime)).unsigned = false;
            else if (typeof object.appointmentTime === "string")
                message.appointmentTime = parseInt(object.appointmentTime, 10);
            else if (typeof object.appointmentTime === "number")
                message.appointmentTime = object.appointmentTime;
            else if (typeof object.appointmentTime === "object")
                message.appointmentTime = new $util.LongBits(object.appointmentTime.low >>> 0, object.appointmentTime.high >>> 0).toNumber();
        return message;
    };

    /**
     * Creates a plain object from a BookAppointmentRequest message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BookAppointmentRequest
     * @static
     * @param {BookAppointmentRequest} message BookAppointmentRequest
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BookAppointmentRequest.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.providerId = "";
            object.serviceId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.appointmentTime = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.appointmentTime = options.longs === String ? "0" : 0;
        }
        if (message.providerId != null && message.hasOwnProperty("providerId"))
            object.providerId = message.providerId;
        if (message.serviceId != null && message.hasOwnProperty("serviceId"))
            object.serviceId = message.serviceId;
        if (message.appointmentTime != null && message.hasOwnProperty("appointmentTime"))
            if (typeof message.appointmentTime === "number")
                object.appointmentTime = options.longs === String ? String(message.appointmentTime) : message.appointmentTime;
            else
                object.appointmentTime = options.longs === String ? $util.Long.prototype.toString.call(message.appointmentTime) : options.longs === Number ? new $util.LongBits(message.appointmentTime.low >>> 0, message.appointmentTime.high >>> 0).toNumber() : message.appointmentTime;
        return object;
    };

    /**
     * Converts this BookAppointmentRequest to JSON.
     * @function toJSON
     * @memberof BookAppointmentRequest
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BookAppointmentRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BookAppointmentRequest
     * @function getTypeUrl
     * @memberof BookAppointmentRequest
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BookAppointmentRequest.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BookAppointmentRequest";
    };

    return BookAppointmentRequest;
})();

$root.BookAppointmentResponse = (function() {

    /**
     * Properties of a BookAppointmentResponse.
     * @exports IBookAppointmentResponse
     * @interface IBookAppointmentResponse
     * @property {boolean|null} [okay] BookAppointmentResponse okay
     * @property {string|null} [error] BookAppointmentResponse error
     * @property {IClientAppointmentProto|null} [appointment] BookAppointmentResponse appointment
     * @property {IUserProfileProto|null} [updatedProfile] BookAppointmentResponse updatedProfile
     */

    /**
     * Constructs a new BookAppointmentResponse.
     * @exports BookAppointmentResponse
     * @classdesc Represents a BookAppointmentResponse.
     * @implements IBookAppointmentResponse
     * @constructor
     * @param {IBookAppointmentResponse=} [properties] Properties to set
     */
    function BookAppointmentResponse(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * BookAppointmentResponse okay.
     * @member {boolean} okay
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.okay = false;

    /**
     * BookAppointmentResponse error.
     * @member {string} error
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.error = "";

    /**
     * BookAppointmentResponse appointment.
     * @member {IClientAppointmentProto|null|undefined} appointment
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.appointment = null;

    /**
     * BookAppointmentResponse updatedProfile.
     * @member {IUserProfileProto|null|undefined} updatedProfile
     * @memberof BookAppointmentResponse
     * @instance
     */
    BookAppointmentResponse.prototype.updatedProfile = null;

    /**
     * Creates a new BookAppointmentResponse instance using the specified properties.
     * @function create
     * @memberof BookAppointmentResponse
     * @static
     * @param {IBookAppointmentResponse=} [properties] Properties to set
     * @returns {BookAppointmentResponse} BookAppointmentResponse instance
     */
    BookAppointmentResponse.create = function create(properties) {
        return new BookAppointmentResponse(properties);
    };

    /**
     * Encodes the specified BookAppointmentResponse message. Does not implicitly {@link BookAppointmentResponse.verify|verify} messages.
     * @function encode
     * @memberof BookAppointmentResponse
     * @static
     * @param {IBookAppointmentResponse} message BookAppointmentResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentResponse.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.okay != null && Object.hasOwnProperty.call(message, "okay"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.okay);
        if (message.error != null && Object.hasOwnProperty.call(message, "error"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.error);
        if (message.appointment != null && Object.hasOwnProperty.call(message, "appointment"))
            $root.ClientAppointmentProto.encode(message.appointment, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.updatedProfile != null && Object.hasOwnProperty.call(message, "updatedProfile"))
            $root.UserProfileProto.encode(message.updatedProfile, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Encodes the specified BookAppointmentResponse message, length delimited. Does not implicitly {@link BookAppointmentResponse.verify|verify} messages.
     * @function encodeDelimited
     * @memberof BookAppointmentResponse
     * @static
     * @param {IBookAppointmentResponse} message BookAppointmentResponse message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    BookAppointmentResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a BookAppointmentResponse message from the specified reader or buffer.
     * @function decode
     * @memberof BookAppointmentResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {BookAppointmentResponse} BookAppointmentResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.BookAppointmentResponse();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.okay = reader.bool();
                    break;
                }
            case 2: {
                    message.error = reader.string();
                    break;
                }
            case 3: {
                    message.appointment = $root.ClientAppointmentProto.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.updatedProfile = $root.UserProfileProto.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a BookAppointmentResponse message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof BookAppointmentResponse
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {BookAppointmentResponse} BookAppointmentResponse
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    BookAppointmentResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a BookAppointmentResponse message.
     * @function verify
     * @memberof BookAppointmentResponse
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    BookAppointmentResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.okay != null && message.hasOwnProperty("okay"))
            if (typeof message.okay !== "boolean")
                return "okay: boolean expected";
        if (message.error != null && message.hasOwnProperty("error"))
            if (!$util.isString(message.error))
                return "error: string expected";
        if (message.appointment != null && message.hasOwnProperty("appointment")) {
            var error = $root.ClientAppointmentProto.verify(message.appointment);
            if (error)
                return "appointment." + error;
        }
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile")) {
            var error = $root.UserProfileProto.verify(message.updatedProfile);
            if (error)
                return "updatedProfile." + error;
        }
        return null;
    };

    /**
     * Creates a BookAppointmentResponse message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof BookAppointmentResponse
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {BookAppointmentResponse} BookAppointmentResponse
     */
    BookAppointmentResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.BookAppointmentResponse)
            return object;
        var message = new $root.BookAppointmentResponse();
        if (object.okay != null)
            message.okay = Boolean(object.okay);
        if (object.error != null)
            message.error = String(object.error);
        if (object.appointment != null) {
            if (typeof object.appointment !== "object")
                throw TypeError(".BookAppointmentResponse.appointment: object expected");
            message.appointment = $root.ClientAppointmentProto.fromObject(object.appointment);
        }
        if (object.updatedProfile != null) {
            if (typeof object.updatedProfile !== "object")
                throw TypeError(".BookAppointmentResponse.updatedProfile: object expected");
            message.updatedProfile = $root.UserProfileProto.fromObject(object.updatedProfile);
        }
        return message;
    };

    /**
     * Creates a plain object from a BookAppointmentResponse message. Also converts values to other types if specified.
     * @function toObject
     * @memberof BookAppointmentResponse
     * @static
     * @param {BookAppointmentResponse} message BookAppointmentResponse
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    BookAppointmentResponse.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.okay = false;
            object.error = "";
            object.appointment = null;
            object.updatedProfile = null;
        }
        if (message.okay != null && message.hasOwnProperty("okay"))
            object.okay = message.okay;
        if (message.error != null && message.hasOwnProperty("error"))
            object.error = message.error;
        if (message.appointment != null && message.hasOwnProperty("appointment"))
            object.appointment = $root.ClientAppointmentProto.toObject(message.appointment, options);
        if (message.updatedProfile != null && message.hasOwnProperty("updatedProfile"))
            object.updatedProfile = $root.UserProfileProto.toObject(message.updatedProfile, options);
        return object;
    };

    /**
     * Converts this BookAppointmentResponse to JSON.
     * @function toJSON
     * @memberof BookAppointmentResponse
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    BookAppointmentResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for BookAppointmentResponse
     * @function getTypeUrl
     * @memberof BookAppointmentResponse
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    BookAppointmentResponse.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/BookAppointmentResponse";
    };

    return BookAppointmentResponse;
})();

$root.ClientAppointmentProto = (function() {

    /**
     * Properties of a ClientAppointmentProto.
     * @exports IClientAppointmentProto
     * @interface IClientAppointmentProto
     * @property {string|null} [appointmentId] ClientAppointmentProto appointmentId
     * @property {number|Long|null} [startTimeMs] ClientAppointmentProto startTimeMs
     * @property {number|Long|null} [endTimeMs] ClientAppointmentProto endTimeMs
     * @property {string|null} [serviceName] ClientAppointmentProto serviceName
     * @property {string|null} [providerName] ClientAppointmentProto providerName
     */

    /**
     * Constructs a new ClientAppointmentProto.
     * @exports ClientAppointmentProto
     * @classdesc Represents a ClientAppointmentProto.
     * @implements IClientAppointmentProto
     * @constructor
     * @param {IClientAppointmentProto=} [properties] Properties to set
     */
    function ClientAppointmentProto(properties) {
        if (properties)
            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ClientAppointmentProto appointmentId.
     * @member {string} appointmentId
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.appointmentId = "";

    /**
     * ClientAppointmentProto startTimeMs.
     * @member {number|Long} startTimeMs
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.startTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ClientAppointmentProto endTimeMs.
     * @member {number|Long} endTimeMs
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.endTimeMs = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

    /**
     * ClientAppointmentProto serviceName.
     * @member {string} serviceName
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.serviceName = "";

    /**
     * ClientAppointmentProto providerName.
     * @member {string} providerName
     * @memberof ClientAppointmentProto
     * @instance
     */
    ClientAppointmentProto.prototype.providerName = "";

    /**
     * Creates a new ClientAppointmentProto instance using the specified properties.
     * @function create
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto=} [properties] Properties to set
     * @returns {ClientAppointmentProto} ClientAppointmentProto instance
     */
    ClientAppointmentProto.create = function create(properties) {
        return new ClientAppointmentProto(properties);
    };

    /**
     * Encodes the specified ClientAppointmentProto message. Does not implicitly {@link ClientAppointmentProto.verify|verify} messages.
     * @function encode
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto} message ClientAppointmentProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientAppointmentProto.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.appointmentId != null && Object.hasOwnProperty.call(message, "appointmentId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.appointmentId);
        if (message.startTimeMs != null && Object.hasOwnProperty.call(message, "startTimeMs"))
            writer.uint32(/* id 2, wireType 0 =*/16).int64(message.startTimeMs);
        if (message.endTimeMs != null && Object.hasOwnProperty.call(message, "endTimeMs"))
            writer.uint32(/* id 3, wireType 0 =*/24).int64(message.endTimeMs);
        if (message.serviceName != null && Object.hasOwnProperty.call(message, "serviceName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.serviceName);
        if (message.providerName != null && Object.hasOwnProperty.call(message, "providerName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.providerName);
        return writer;
    };

    /**
     * Encodes the specified ClientAppointmentProto message, length delimited. Does not implicitly {@link ClientAppointmentProto.verify|verify} messages.
     * @function encodeDelimited
     * @memberof ClientAppointmentProto
     * @static
     * @param {IClientAppointmentProto} message ClientAppointmentProto message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ClientAppointmentProto.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
    };

    /**
     * Decodes a ClientAppointmentProto message from the specified reader or buffer.
     * @function decode
     * @memberof ClientAppointmentProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientAppointmentProto.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.ClientAppointmentProto();
        while (reader.pos < end) {
            var tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.appointmentId = reader.string();
                    break;
                }
            case 2: {
                    message.startTimeMs = reader.int64();
                    break;
                }
            case 3: {
                    message.endTimeMs = reader.int64();
                    break;
                }
            case 4: {
                    message.serviceName = reader.string();
                    break;
                }
            case 5: {
                    message.providerName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Decodes a ClientAppointmentProto message from the specified reader or buffer, length delimited.
     * @function decodeDelimited
     * @memberof ClientAppointmentProto
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ClientAppointmentProto.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader))
            reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
    };

    /**
     * Verifies a ClientAppointmentProto message.
     * @function verify
     * @memberof ClientAppointmentProto
     * @static
     * @param {Object.<string,*>} message Plain object to verify
     * @returns {string|null} `null` if valid, otherwise the reason why it is not
     */
    ClientAppointmentProto.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
            return "object expected";
        if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
            if (!$util.isString(message.appointmentId))
                return "appointmentId: string expected";
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (!$util.isInteger(message.startTimeMs) && !(message.startTimeMs && $util.isInteger(message.startTimeMs.low) && $util.isInteger(message.startTimeMs.high)))
                return "startTimeMs: integer|Long expected";
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (!$util.isInteger(message.endTimeMs) && !(message.endTimeMs && $util.isInteger(message.endTimeMs.low) && $util.isInteger(message.endTimeMs.high)))
                return "endTimeMs: integer|Long expected";
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            if (!$util.isString(message.serviceName))
                return "serviceName: string expected";
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            if (!$util.isString(message.providerName))
                return "providerName: string expected";
        return null;
    };

    /**
     * Creates a ClientAppointmentProto message from a plain object. Also converts values to their respective internal types.
     * @function fromObject
     * @memberof ClientAppointmentProto
     * @static
     * @param {Object.<string,*>} object Plain object
     * @returns {ClientAppointmentProto} ClientAppointmentProto
     */
    ClientAppointmentProto.fromObject = function fromObject(object) {
        if (object instanceof $root.ClientAppointmentProto)
            return object;
        var message = new $root.ClientAppointmentProto();
        if (object.appointmentId != null)
            message.appointmentId = String(object.appointmentId);
        if (object.startTimeMs != null)
            if ($util.Long)
                (message.startTimeMs = $util.Long.fromValue(object.startTimeMs)).unsigned = false;
            else if (typeof object.startTimeMs === "string")
                message.startTimeMs = parseInt(object.startTimeMs, 10);
            else if (typeof object.startTimeMs === "number")
                message.startTimeMs = object.startTimeMs;
            else if (typeof object.startTimeMs === "object")
                message.startTimeMs = new $util.LongBits(object.startTimeMs.low >>> 0, object.startTimeMs.high >>> 0).toNumber();
        if (object.endTimeMs != null)
            if ($util.Long)
                (message.endTimeMs = $util.Long.fromValue(object.endTimeMs)).unsigned = false;
            else if (typeof object.endTimeMs === "string")
                message.endTimeMs = parseInt(object.endTimeMs, 10);
            else if (typeof object.endTimeMs === "number")
                message.endTimeMs = object.endTimeMs;
            else if (typeof object.endTimeMs === "object")
                message.endTimeMs = new $util.LongBits(object.endTimeMs.low >>> 0, object.endTimeMs.high >>> 0).toNumber();
        if (object.serviceName != null)
            message.serviceName = String(object.serviceName);
        if (object.providerName != null)
            message.providerName = String(object.providerName);
        return message;
    };

    /**
     * Creates a plain object from a ClientAppointmentProto message. Also converts values to other types if specified.
     * @function toObject
     * @memberof ClientAppointmentProto
     * @static
     * @param {ClientAppointmentProto} message ClientAppointmentProto
     * @param {$protobuf.IConversionOptions} [options] Conversion options
     * @returns {Object.<string,*>} Plain object
     */
    ClientAppointmentProto.toObject = function toObject(message, options) {
        if (!options)
            options = {};
        var object = {};
        if (options.defaults) {
            object.appointmentId = "";
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.startTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.startTimeMs = options.longs === String ? "0" : 0;
            if ($util.Long) {
                var long = new $util.Long(0, 0, false);
                object.endTimeMs = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
            } else
                object.endTimeMs = options.longs === String ? "0" : 0;
            object.serviceName = "";
            object.providerName = "";
        }
        if (message.appointmentId != null && message.hasOwnProperty("appointmentId"))
            object.appointmentId = message.appointmentId;
        if (message.startTimeMs != null && message.hasOwnProperty("startTimeMs"))
            if (typeof message.startTimeMs === "number")
                object.startTimeMs = options.longs === String ? String(message.startTimeMs) : message.startTimeMs;
            else
                object.startTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.startTimeMs) : options.longs === Number ? new $util.LongBits(message.startTimeMs.low >>> 0, message.startTimeMs.high >>> 0).toNumber() : message.startTimeMs;
        if (message.endTimeMs != null && message.hasOwnProperty("endTimeMs"))
            if (typeof message.endTimeMs === "number")
                object.endTimeMs = options.longs === String ? String(message.endTimeMs) : message.endTimeMs;
            else
                object.endTimeMs = options.longs === String ? $util.Long.prototype.toString.call(message.endTimeMs) : options.longs === Number ? new $util.LongBits(message.endTimeMs.low >>> 0, message.endTimeMs.high >>> 0).toNumber() : message.endTimeMs;
        if (message.serviceName != null && message.hasOwnProperty("serviceName"))
            object.serviceName = message.serviceName;
        if (message.providerName != null && message.hasOwnProperty("providerName"))
            object.providerName = message.providerName;
        return object;
    };

    /**
     * Converts this ClientAppointmentProto to JSON.
     * @function toJSON
     * @memberof ClientAppointmentProto
     * @instance
     * @returns {Object.<string,*>} JSON object
     */
    ClientAppointmentProto.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
    };

    /**
     * Gets the default type url for ClientAppointmentProto
     * @function getTypeUrl
     * @memberof ClientAppointmentProto
     * @static
     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
     * @returns {string} The default type url
     */
    ClientAppointmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
        if (typeUrlPrefix === undefined) {
            typeUrlPrefix = "type.googleapis.com";
        }
        return typeUrlPrefix + "/ClientAppointmentProto";
    };

    return ClientAppointmentProto;
})();

$root.space = (function() {

    /**
     * Namespace space.
     * @exports space
     * @namespace
     */
    var space = {};

    space.kenko = (function() {

        /**
         * Namespace kenko.
         * @memberof space
         * @namespace
         */
        var kenko = {};

        kenko.proto = (function() {

            /**
             * Namespace proto.
             * @memberof space.kenko
             * @namespace
             */
            var proto = {};

            proto.WellnessSurveyProto = (function() {

                /**
                 * Properties of a WellnessSurveyProto.
                 * @memberof space.kenko.proto
                 * @interface IWellnessSurveyProto
                 * @property {Array.<string>|null} [holisticHealthInterests] WellnessSurveyProto holisticHealthInterests
                 * @property {string|null} [familiarityWithEasternDietaryPractices] WellnessSurveyProto familiarityWithEasternDietaryPractices
                 * @property {Array.<string>|null} [fitnessGoals] WellnessSurveyProto fitnessGoals
                 * @property {string|null} [currentSkincareRoutine] WellnessSurveyProto currentSkincareRoutine
                 * @property {Array.<string>|null} [hairRelatedConcerns] WellnessSurveyProto hairRelatedConcerns
                 */

                /**
                 * Constructs a new WellnessSurveyProto.
                 * @memberof space.kenko.proto
                 * @classdesc Represents a WellnessSurveyProto.
                 * @implements IWellnessSurveyProto
                 * @constructor
                 * @param {space.kenko.proto.IWellnessSurveyProto=} [properties] Properties to set
                 */
                function WellnessSurveyProto(properties) {
                    this.holisticHealthInterests = [];
                    this.fitnessGoals = [];
                    this.hairRelatedConcerns = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WellnessSurveyProto holisticHealthInterests.
                 * @member {Array.<string>} holisticHealthInterests
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @instance
                 */
                WellnessSurveyProto.prototype.holisticHealthInterests = $util.emptyArray;

                /**
                 * WellnessSurveyProto familiarityWithEasternDietaryPractices.
                 * @member {string} familiarityWithEasternDietaryPractices
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @instance
                 */
                WellnessSurveyProto.prototype.familiarityWithEasternDietaryPractices = "";

                /**
                 * WellnessSurveyProto fitnessGoals.
                 * @member {Array.<string>} fitnessGoals
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @instance
                 */
                WellnessSurveyProto.prototype.fitnessGoals = $util.emptyArray;

                /**
                 * WellnessSurveyProto currentSkincareRoutine.
                 * @member {string} currentSkincareRoutine
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @instance
                 */
                WellnessSurveyProto.prototype.currentSkincareRoutine = "";

                /**
                 * WellnessSurveyProto hairRelatedConcerns.
                 * @member {Array.<string>} hairRelatedConcerns
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @instance
                 */
                WellnessSurveyProto.prototype.hairRelatedConcerns = $util.emptyArray;

                /**
                 * Creates a new WellnessSurveyProto instance using the specified properties.
                 * @function create
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveyProto=} [properties] Properties to set
                 * @returns {space.kenko.proto.WellnessSurveyProto} WellnessSurveyProto instance
                 */
                WellnessSurveyProto.create = function create(properties) {
                    return new WellnessSurveyProto(properties);
                };

                /**
                 * Encodes the specified WellnessSurveyProto message. Does not implicitly {@link space.kenko.proto.WellnessSurveyProto.verify|verify} messages.
                 * @function encode
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveyProto} message WellnessSurveyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveyProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.holisticHealthInterests != null && message.holisticHealthInterests.length)
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.holisticHealthInterests[i]);
                    if (message.familiarityWithEasternDietaryPractices != null && Object.hasOwnProperty.call(message, "familiarityWithEasternDietaryPractices"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.familiarityWithEasternDietaryPractices);
                    if (message.fitnessGoals != null && message.fitnessGoals.length)
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.fitnessGoals[i]);
                    if (message.currentSkincareRoutine != null && Object.hasOwnProperty.call(message, "currentSkincareRoutine"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.currentSkincareRoutine);
                    if (message.hairRelatedConcerns != null && message.hairRelatedConcerns.length)
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.hairRelatedConcerns[i]);
                    return writer;
                };

                /**
                 * Encodes the specified WellnessSurveyProto message, length delimited. Does not implicitly {@link space.kenko.proto.WellnessSurveyProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {space.kenko.proto.IWellnessSurveyProto} message WellnessSurveyProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WellnessSurveyProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WellnessSurveyProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {space.kenko.proto.WellnessSurveyProto} WellnessSurveyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveyProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.space.kenko.proto.WellnessSurveyProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.holisticHealthInterests && message.holisticHealthInterests.length))
                                    message.holisticHealthInterests = [];
                                message.holisticHealthInterests.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.familiarityWithEasternDietaryPractices = reader.string();
                                break;
                            }
                        case 3: {
                                if (!(message.fitnessGoals && message.fitnessGoals.length))
                                    message.fitnessGoals = [];
                                message.fitnessGoals.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.currentSkincareRoutine = reader.string();
                                break;
                            }
                        case 5: {
                                if (!(message.hairRelatedConcerns && message.hairRelatedConcerns.length))
                                    message.hairRelatedConcerns = [];
                                message.hairRelatedConcerns.push(reader.string());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WellnessSurveyProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {space.kenko.proto.WellnessSurveyProto} WellnessSurveyProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WellnessSurveyProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WellnessSurveyProto message.
                 * @function verify
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WellnessSurveyProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.holisticHealthInterests != null && message.hasOwnProperty("holisticHealthInterests")) {
                        if (!Array.isArray(message.holisticHealthInterests))
                            return "holisticHealthInterests: array expected";
                        for (var i = 0; i < message.holisticHealthInterests.length; ++i)
                            if (!$util.isString(message.holisticHealthInterests[i]))
                                return "holisticHealthInterests: string[] expected";
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        if (!$util.isString(message.familiarityWithEasternDietaryPractices))
                            return "familiarityWithEasternDietaryPractices: string expected";
                    if (message.fitnessGoals != null && message.hasOwnProperty("fitnessGoals")) {
                        if (!Array.isArray(message.fitnessGoals))
                            return "fitnessGoals: array expected";
                        for (var i = 0; i < message.fitnessGoals.length; ++i)
                            if (!$util.isString(message.fitnessGoals[i]))
                                return "fitnessGoals: string[] expected";
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        if (!$util.isString(message.currentSkincareRoutine))
                            return "currentSkincareRoutine: string expected";
                    if (message.hairRelatedConcerns != null && message.hasOwnProperty("hairRelatedConcerns")) {
                        if (!Array.isArray(message.hairRelatedConcerns))
                            return "hairRelatedConcerns: array expected";
                        for (var i = 0; i < message.hairRelatedConcerns.length; ++i)
                            if (!$util.isString(message.hairRelatedConcerns[i]))
                                return "hairRelatedConcerns: string[] expected";
                    }
                    return null;
                };

                /**
                 * Creates a WellnessSurveyProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {space.kenko.proto.WellnessSurveyProto} WellnessSurveyProto
                 */
                WellnessSurveyProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.space.kenko.proto.WellnessSurveyProto)
                        return object;
                    var message = new $root.space.kenko.proto.WellnessSurveyProto();
                    if (object.holisticHealthInterests) {
                        if (!Array.isArray(object.holisticHealthInterests))
                            throw TypeError(".space.kenko.proto.WellnessSurveyProto.holisticHealthInterests: array expected");
                        message.holisticHealthInterests = [];
                        for (var i = 0; i < object.holisticHealthInterests.length; ++i)
                            message.holisticHealthInterests[i] = String(object.holisticHealthInterests[i]);
                    }
                    if (object.familiarityWithEasternDietaryPractices != null)
                        message.familiarityWithEasternDietaryPractices = String(object.familiarityWithEasternDietaryPractices);
                    if (object.fitnessGoals) {
                        if (!Array.isArray(object.fitnessGoals))
                            throw TypeError(".space.kenko.proto.WellnessSurveyProto.fitnessGoals: array expected");
                        message.fitnessGoals = [];
                        for (var i = 0; i < object.fitnessGoals.length; ++i)
                            message.fitnessGoals[i] = String(object.fitnessGoals[i]);
                    }
                    if (object.currentSkincareRoutine != null)
                        message.currentSkincareRoutine = String(object.currentSkincareRoutine);
                    if (object.hairRelatedConcerns) {
                        if (!Array.isArray(object.hairRelatedConcerns))
                            throw TypeError(".space.kenko.proto.WellnessSurveyProto.hairRelatedConcerns: array expected");
                        message.hairRelatedConcerns = [];
                        for (var i = 0; i < object.hairRelatedConcerns.length; ++i)
                            message.hairRelatedConcerns[i] = String(object.hairRelatedConcerns[i]);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WellnessSurveyProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {space.kenko.proto.WellnessSurveyProto} message WellnessSurveyProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WellnessSurveyProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.holisticHealthInterests = [];
                        object.fitnessGoals = [];
                        object.hairRelatedConcerns = [];
                    }
                    if (options.defaults) {
                        object.familiarityWithEasternDietaryPractices = "";
                        object.currentSkincareRoutine = "";
                    }
                    if (message.holisticHealthInterests && message.holisticHealthInterests.length) {
                        object.holisticHealthInterests = [];
                        for (var j = 0; j < message.holisticHealthInterests.length; ++j)
                            object.holisticHealthInterests[j] = message.holisticHealthInterests[j];
                    }
                    if (message.familiarityWithEasternDietaryPractices != null && message.hasOwnProperty("familiarityWithEasternDietaryPractices"))
                        object.familiarityWithEasternDietaryPractices = message.familiarityWithEasternDietaryPractices;
                    if (message.fitnessGoals && message.fitnessGoals.length) {
                        object.fitnessGoals = [];
                        for (var j = 0; j < message.fitnessGoals.length; ++j)
                            object.fitnessGoals[j] = message.fitnessGoals[j];
                    }
                    if (message.currentSkincareRoutine != null && message.hasOwnProperty("currentSkincareRoutine"))
                        object.currentSkincareRoutine = message.currentSkincareRoutine;
                    if (message.hairRelatedConcerns && message.hairRelatedConcerns.length) {
                        object.hairRelatedConcerns = [];
                        for (var j = 0; j < message.hairRelatedConcerns.length; ++j)
                            object.hairRelatedConcerns[j] = message.hairRelatedConcerns[j];
                    }
                    return object;
                };

                /**
                 * Converts this WellnessSurveyProto to JSON.
                 * @function toJSON
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WellnessSurveyProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WellnessSurveyProto
                 * @function getTypeUrl
                 * @memberof space.kenko.proto.WellnessSurveyProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WellnessSurveyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/space.kenko.proto.WellnessSurveyProto";
                };

                return WellnessSurveyProto;
            })();

            return proto;
        })();

        return kenko;
    })();

    return space;
})();

module.exports = $root;
