import React from "react";
import {Link} from "react-router-dom";
import '../App/App.css';

// Much simplifed version of Header.tsx
export const WaitlistHeader = () => {

  const host = window.location.host

  return (
      <div className={'AppSection BeigeSection WaitlistHeader'}>
        <div className={'AppSectionContent Flex1'}>

          <div className={'Header'}>
            <div className={'HeaderLeftElement'}>
              <Link style={{display: 'flex', justifyContent: 'left'}} to={'/'}>
                <img alt={'Kenko Logo'} src={require('../images/logo.png')}
                     className={'HeaderLogo'}/>
              </Link>
              {host.startsWith("localhost") && <span style={{color: 'blue'}}>local&nbsp;</span>}
              {host.endsWith("thymebook.com") && <span style={{color: 'green'}}>dev&nbsp;</span>}
            </div>
          </div>
        </div>
      </div>
  );
} 