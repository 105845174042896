import {Outlet, Route, Routes, useLocation} from 'react-router-dom';
import {EmailSignup} from "./EmailSignup";
import './Article.css';
import {ZhouYueZi} from "./Content/ZhouYueZi";
import {BenefitsOfAcupuncture} from "./Content/BenefitsOfAcupuncture";
import {InvestingInSelfCare} from "./Content/InvestingInSelfCare";
import {useLayoutEffect} from "react";
import {SocialLinks} from "../App/SocialLinks";

export const Article = () => {

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Trigger on route change

  return (<>
    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent ArticleSection'}>
        <div style={{display: 'flex', flexDirection: 'column'}}>

          <Routes>
            <Route path={'zhou_yue_zi'} element={<ZhouYueZi/>}/>
            <Route path={'healing_power_of_acupuncture'} element={<BenefitsOfAcupuncture/>}/>
            <Route path={'investing_in_self_care'} element={<InvestingInSelfCare/>}/>
          </Routes>

        </div>
      </div>
    </div>

    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent ArticleSocialLinks'}>
        <SocialLinks lightBackground={true}/>
      </div>
    </div>

    <div className={'AppSection LightRedSection'}>
      <div className={'AppSectionContent AboutKenkoSection'}>

        <h2>About Kenko</h2>
        <div>
          We’re blending ancient healing wisdom with modern AI to craft personalized paths to wellness—invest in your
          wellbeing and transform your future health.
        </div>
        <div style={{marginTop: 30}}>
          <EmailSignup/>
        </div>
      </div>
    </div>
    <div className={'AppSection WhiteSection'}>
      <div className={'AppSectionContent DisclaimerSection'}>
        The information, including but not limited to, text, graphics,
        images and other material contained on this website are for
        informational purposes only. No material on this site is intended to
        be a substitute for professional medical advice, diagnosis or
        treatment. Always seek the advice of your physician or other
        qualified healthcare provider, and never disregard professional
        medical advice or delay in seeking it because of something you have
        read on this site. Use of this site is subject to our Terms of Use
        and Privacy Policy.
      </div>
    </div>

  </>);
};
