import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router-dom";
import {askLlama} from "../../components/chat-api";
import {useAuth0} from "@auth0/auth0-react";
import Markdown from 'react-markdown';
import '../App.css';
import {useProfile} from "../../UserProfileProvider";

// This is the type that is passed in the navigate state.
export type AiChatPageState = {
  message: string
}

// The text in these can be appended to; the RPC sender keeps
// adding to the message when streaming a response.
export type ChatMessage = {
  outgoing: boolean,
  message: string,
  timestamp: number,
  done: boolean,
  error?: string,
}

export const AiChatPage = () => {

  const location = useLocation();
  
  const {profile} = useProfile();

  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const {getIdTokenClaims} = useAuth0()

  // The initial query was getting send twice because of strict mode
  // and the setState was not getting picked up in time.
  // Should really do this
  // https://react.dev/learn/synchronizing-with-effects#fetching-data
  const isFirstRender = useRef(true);

  let state = location.state as AiChatPageState;

  // Sets the initial message, I guess? Is this a hack?
  useEffect(() => {
    if (messages.length == 0 && state?.message && isFirstRender.current) {
      isFirstRender.current = false;
      sendQuery(state.message);
    }
  }, [messages])

  const sendQuery = (query: string) => {

    let newMessage = {
      message: query,
      outgoing: true,
      done: true,
      timestamp: new Date().getTime()
    };
    setMessages(messages => [...messages, newMessage]);

    // Double duplication of array? Kind of annoying..
    askLlama(getIdTokenClaims, [...messages, newMessage])
        .then(
            response => {
              handleResponse(response);
            }
        )
        .catch(e => {
          console.error(e);
          setMessages(messages => [...messages, {
            message: 'Response error: ' + e,
            outgoing: false,
            timestamp: new Date().getTime(),
            done: true,
            error: 'Error: ' + e
          }]);
        })
  }

  const handleResponse = async (response: Response) => {
    if (response.ok && response.body) {

      // This is a readable stream okay.... 
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

      let message: ChatMessage = {
        // This keeps getting appended to.
        message: '',
        outgoing: false,
        done: false,
        timestamp: new Date().getTime()
      };

      // Add the initial empty response here...
      setMessages(messages => [...messages, message]);

      while (true) {
        const {done, value} = await reader.read();

        // Doing the update on EITHER is an effort to update
        // the state only once during the frame.
        if (value || done) {


          // Can be multiple data chunks here!!
          value?.split('\n')?.forEach(line => {
            if (line.trim().length != 0) {

              // Chop of the 'data:' at the beginning of each.
              let text1 = line.substring(6);

              if (text1.startsWith('[DONE]')) {
                return;
              }

              let text = value ? JSON.parse(text1) : '';
              message.message += text.choices[0].delta.content;
            }
          });

          message.done = done;

          // https://react.dev/learn/updating-arrays-in-state
          //
          // This doesn't work with the non-lambda version.
          // Apparently multiple updates per frame happen.
          setMessages(messages => messages.map(existing => {
            if (existing.timestamp == message.timestamp) {
              // Create a new object at this spot in the array.
              return {...message};
            } else {
              return existing;
            }
          }));
        }

        if (done) {

          console.log('Handled complete message', message.message)

          return;
        }
      }
    }
  }

  return (
      <div className={'AppSection BeigeSection'} style={{borderTop: '2px solid black'}}>
        <div className={'AppSectionContent'}>

          <div className={'ChatPage'}>

            <div className={'ChatPageLeft'}>

              <div className={'NewConversationButton'}>+ New Conversation</div>

              {messages?.length > 0 && <>
                <div><b>TODAY</b></div>

                <div className={'HorizontalFlexBox'} style={{gap: 10}}>
                  <img style={{height: 32}} src={require('../../images/chat.png')} alt={'conversation icon'}/>
                  <div>{messages[0].message}</div>
                </div>

              </>}

            </div>

            <div className={'ChatPageRight'}>
              {messages?.map(message => {

                return <div className={message.outgoing ? 'ChatRow ChatRowUser' : 'ChatRow'}>
                  
                  <div className={message.outgoing ? ' ChatInitial ChatInitialUser' : 'ChatInitial'}>
                    {message.outgoing ? (profile?.name?.length ? profile.name[0] : 'M') : 'K'}
                  </div>
                  
                  <div
                      key={message.timestamp}
                      className={message.outgoing ? 'ChatMessage ChatMessageUser' : 'ChatMessage ChatMessageAssistant'}
                  >

                    <Markdown className={'ChatMarkdown'}>
                      {message.message}
                    </Markdown>
                  </div>
                </div>
              })}


              <input type={'text'}
                     inputMode={'search'}
                     className={'ChatPageInput'}
                     onKeyPress={e => {
                       if (e.key == 'Enter') {
                         console.log('query - ', e.currentTarget.value)
                         sendQuery(e.currentTarget.value)
                         e.currentTarget.value = ''
                       }
                     }}/>

            </div>

          </div>


        </div>
      </div>
  )
}