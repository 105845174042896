import React from "react";
import '../../App.css';

/**
 * This is shared between the regular booking site and the waitlist... so it's here for now.
 */
export const WhatIsKenkoSection = () => {

  return <div className={'AppSection WhiteSection'}>
    <div className={'AppSectionContent WhatIsKenkoSection'}>

      <div style={{flex: 1}}>
        <div className={'WhatIsKenkoLeft'}>
          <h2>What is Kenko?</h2>
          <p>
            Kenko (健康) means “health”. The first character means a state of great strength, fullness and
            self-support. The second means peacefulness or serenity of mind without stress.
          </p>
          <p>
            At Kenko, we’ve built a platform that helps you navigate holistic and preventative care. It’s never
            the wrong time to prioritize your health, and with Kenko, it’s easier than ever.
          </p>
        </div>
      </div>

      <div className={'WhatIsKenkoRight'}>
        <div/>
        {/* placeholder, the background image is used so it can scale*/}
      </div>
    </div>

  </div>
}