import {Route, Routes} from "react-router-dom";
import {Article} from "./Article";
import React from "react";
import {Waitlist} from "./Waitlist";
import {WaitlistHeader} from "./WaitlistHeader";
import Footer from "../App/Footer";
import {Privacy} from "../Privacy";

export const WaitlistRouter = () => {

  return <>
    <WaitlistHeader/>
    <div className="App">
      <Routes>
        <Route path={'/article/*'} element={<Article/>}/>
        <Route path={'/privacy'} element={<Privacy/>}/>
        <Route path={'/*'} element={<Waitlist/>}/>
      </Routes>
    </div>
    <Footer/>


  </>
}