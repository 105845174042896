import {FacebookShareButton} from "react-share";

type Props = {
  lightBackground: boolean
}

export const SocialLinks = (props: Props) => {
  return (
      <div className={'SocialLinks'}>
        
        <a href={'https://www.instagram.com/kenko_space/'} target={'_blank'}>
          <img alt={'Kenko on Instagram'}
               className={'SocialLink'}
               src={props.lightBackground ? require('../images/icons/ig_light.png') :
                   require('../images/icons/ig_dark.png')}/>
        </a>
        <FacebookShareButton url={window.location.href}>
          <img alt={'Kenko on Facebook'}
               className={'SocialLink'}
               src={props.lightBackground ? require('../images/icons/fb_light.png') :
                   require('../images/icons/fb_dark.png')}/>
        </FacebookShareButton>

        <a href={'https://x.com/kenkospace'} target={'_blank'}>
          <img alt={'Kenko on X'}
               className={'SocialLink'}
               src={props.lightBackground ? require('../images/icons/twitter_light.png') :
                   require('../images/icons/twitter_dark.png')}/>
        </a>
        <a href={'https://www.tiktok.com/@kenkospace'} target={'_blank'}>
          <img alt={'Kenko on TikTok'}
               className={'SocialLink'}
               src={props.lightBackground ? require('../images/icons/tt_light.png') :
                   require('../images/icons/tt_dark.png')}/>
        </a>
      </div>
  );
};