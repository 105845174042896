import {ArticlePreview} from "../ArticlePreview";
import React from "react";

/**
 * Used in the regular homepage and waitlist.
 */
export const ArticlePreviewSection = () => {

  return (<div className="AppSection BeigeSection">
    <div className="AppSectionContent">

      <div className={'ArticlePreviewSection'}>

        <ArticlePreview link={'article/investing_in_self_care'}
                        imgSource={require('../../../images/articles/22.png')}
                        category={'LONGEVITY PLANNING'}
                        title={'The Importance of Investing in Self-Care and Holistic Wellness for Women'}/>

        <ArticlePreview link={'article/healing_power_of_acupuncture'}
                        imgSource={require('../../../images/articles/23.png')}
                        category={'EASTERN MEDICINE'}
                        title={'Evidence-Based Research on the Benefits of Acupuncture'}/>

        <ArticlePreview link={'article/zhou_yue_zi'}
                        imgSource={require('../../../images/articles/24.png')}
                        category={'POSTPARTUM CARE'}
                        title={'Embracing “Zuo Yue Zi": The Benefits of Traditional Postpartum Care for Modern Mothers'}/>

      </div>
    </div>
  </div>);
}