import React, {useLayoutEffect} from "react";
import {WhatIsKenkoSection} from "../App/Home/Sections/WhatIsKenkoSection";
import {EmailSignup} from "./EmailSignup";
import '../App/App.css';
import {ArticlePreviewSection} from "../App/Home/Sections/ArticlePreviewSection";
import {useLocation} from "react-router-dom";

export const Waitlist = () => {

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]); // Trigger on route change

  return <>
    <TransformYourFutureHealth/>
    <WhatIsKenkoSection/>
    <ArticlePreviewSection/>
  </>;

}

const TransformYourFutureHealth = () => {
  return <div className={'AppSection BeigeSection'}>
    <div className={'AppSectionContent WelcomeSection'}>

      <h1 style={{maxWidth: 600}}>
        Transform Your Future Health.
      </h1>

      <h2 style={{maxWidth: 580}}>
        Expert-led, integrative care that meets you where you’re at today, for a healthier tomorrow.
      </h2>

      <div style={{margin: 50}}>
        <EmailSignup/>
      </div>
    </div>
  </div>
}
